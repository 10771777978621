import BaseModule from "@/includes/logic/Modules/models/BaseModule";
import { ModuleTypesEnum } from "@/includes/logic/Modules/types/types";

import MessageEditorWithMediaData from "piramis-base-components/src/components/NewMessageEditors/NewMessageEditorWithMedia/types";

import { Type } from "class-transformer";

export default class InactiveKickHandlerModule extends BaseModule {
  type: ModuleTypesEnum = ModuleTypesEnum.InactiveKickHandlerModule;

  @Type(() => InactiveKickHandlerModuleParams)
  params!: InactiveKickHandlerModuleParams;
}

class InactiveKickHandlerModuleParams {
    inactive_kick_interval!: number

    @Type(() => MessageEditorWithMediaData)
    inactive_kick_warn1!: Array<MessageEditorWithMediaData>

    @Type(() => MessageEditorWithMediaData)
    inactive_kick_warn2!: Array<MessageEditorWithMediaData>

    inactive_kick_take_away_xp!: number
    inactive_kick_take_away_reputation!: number
}

