import chatMutations     	from "./chats/mutations";
import triggersMutations 	from "./triggers/mutations";
import postsPlannerMutations 	from "./postsPlanner/mutations";
import networksMutations 	from "./networks/mutations";

const mutations = {
	// chats
	...chatMutations,
	// tiggers
	...triggersMutations,
  	// posts planner
	...postsPlannerMutations,
	...networksMutations,
  	set_users(state, payload) {
	  state.dashboardExtendedUsers = payload
	},
  	clear_users(state) {
  	  state.dashboardExtendedUsers = []
  	}
};

export default mutations;
