import { GreetingProtectionTypeEnum } from "../types";
import BaseGreetingProtection from "./BaseGreetingProtection";

export default class BindedChannelGreetingProtection extends BaseGreetingProtection {
  type: GreetingProtectionTypeEnum = GreetingProtectionTypeEnum.BindedChannel

  invite_link!: string | null

  button_text!: string
}
