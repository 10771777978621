import BaseModule from "@/includes/logic/Modules/models/BaseModule";
import { ModuleTypesEnum } from "@/includes/logic/Modules/types/types";
import { PunishActions } from "@/includes/logic/Modules/models/PunishActions/types";

import MessageEditorWithMediaData
  from "piramis-base-components/src/components/NewMessageEditors/NewMessageEditorWithMedia/types";
import { BlackWhiteListItem } from "piramis-base-components/src/components/BlackWhiteList/types";

import { Type } from "class-transformer";

export default class RestrictCommentHandlerModule extends BaseModule {
  type: ModuleTypesEnum = ModuleTypesEnum.RestrictCommentHandlerModule;

  @Type(() => RestrictCommentHandlerModuleParams)
  params!: RestrictCommentHandlerModuleParams;
}

class RestrictCommentHandlerModuleParams {
  spam_timeout!: number
  spam_punish_action!: PunishActions
  allow_timeout!: number

  @Type(() => MessageEditorWithMediaData)
  close_messages!: Array<MessageEditorWithMediaData>

  stopWords!: BlackWhiteListItem<string>
}
