import ResolveFromRankCondition from "./models/conditions/ResolveFromRankCondition/ResolveFromRankCondition";
import ResolveFromRepCondition from "./models/conditions/ResolveFromRepCondition/ResolveFromRepCondition";
import TopicsCondition from "./models/conditions/TopicsCondition/TopicsCondition";
import UsersCondition from "./models/conditions/UsersCondition/UsersCondition";
import ResolveFromXpCondition from "./models/conditions/ResolveFromXpCondition/ResolveFromXpCondition";
import ResolveFromApCondition from "./models/conditions/ResolveFromApCondition/ResolveFromApCondition";
import ZoneCondition from "./models/conditions/ZoneCondition/ZoneCondition";
import WorkTimeCondition from "./models/conditions/WorkTimeCondition/WorkTimeCondition";
import ConfigCondition from "./models/ConfigCondition";
import BoostsCondition from "./models/conditions/BoostsCondition/BoostsCondition";
import UserTypeCondition from "./models/conditions/UserType/UserTypeCondition";
import MessageContentTypeCondition from "./models/conditions/MessageContentTypeCondition/MessageContentTypeCondition";
import { ModuleConditionTypeEnum } from "./types/types";

import { ClassConstructor } from "class-transformer";

export const ConfigConditionTypes: Array<{ name: ModuleConditionTypeEnum, value: ClassConstructor<ConfigCondition> }> = [
  {
    name: ModuleConditionTypeEnum.Topics,
    value: TopicsCondition,
  },
  {
    name: ModuleConditionTypeEnum.Zone,
    value: ZoneCondition,
  },
  {
    name: ModuleConditionTypeEnum.Users,
    value: UsersCondition,
  },
  {
    name: ModuleConditionTypeEnum.WorkTime,
    value: WorkTimeCondition,
  },
  {
    name: ModuleConditionTypeEnum.ResolveFromRank,
    value: ResolveFromRankCondition,
  },
  {
    name: ModuleConditionTypeEnum.ResolveFromXp,
    value: ResolveFromXpCondition,
  },
  {
    name: ModuleConditionTypeEnum.ResolveFromRep,
    value: ResolveFromRepCondition,
  },
  {
    name: ModuleConditionTypeEnum.ResolveFromAp,
    value: ResolveFromApCondition,
  },
  {
    name: ModuleConditionTypeEnum.Boosts,
    value: BoostsCondition,
  },
  {
    name: ModuleConditionTypeEnum.UserType,
    value: UserTypeCondition,
  },
  {
    name: ModuleConditionTypeEnum.MessageContentType,
    value: MessageContentTypeCondition,
  },
]
