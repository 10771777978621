class Config {
	constructor(config) {
		this.config = config;
	}

	get projectName() {
		return this.config.name;
	}

	get siteUrl() {
		return this.config.siteUrl;
	}

	get logo() {
		return this.config.assets.images.logo.light;
	}

	get darkLogo() {
		return this.config.assets.images.logo.dark;
	}

	get fullLogo() {
		return this.config.assets.images.fullLogo.light;
	}

	get fullDarkLogo() {
		return this.config.assets.images.fullLogo.dark;
	}

	get lightTheme() {
		return this.config.theme.light;
	}

	get darkTheme() {
		if(this.config.theme.dark) {
			return this.config.theme.dark;
		} else {
			return this.config.theme.light;
		}
	}

	get notAvailableRoutes() {
		return this.config.notAvailableRoutes;
	}

	showOption(optionKey) {
		return this.config.options.includes(optionKey);
	}

	getTheme(key) {
		if(key === "light") {
			return this.lightTheme;
		}
		if(key === "dark") {
			return this.darkTheme;
		}
	}

	getTariff(key) {
		if (key && this.config.tariffs[ key.toLowerCase() ]) {
			return this.config.tariffs[ key.toLowerCase() ];
		} else {
			return {
				img: '',
				color: ''
			}
		}
	}

	getInstructionUrl(lang) {
		if(lang === 'ru') {
			return this.config.instructionUrl + 'ru/'
		}

		if(lang === 'en') {
			return this.config.instructionUrl + 'en/'
		}

		return this.config.instructionUrl
	}

	getPowerOfAttorneyLink(lang) {
	  return this.config.documents.powerOfAttorney.links[lang === 'ru' ? 'ru' : 'en']
	}

	getHowToRegisterUr(lang) {
	  return this.config.documents.howToRegisterUr.links[lang === 'ru' ? 'ru' : 'en']
	}
}

module.exports = class ConfigWrapper {
	static create(file) {
		return new Config(file);
	}
};
