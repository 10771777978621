import BaseModule from "@/includes/logic/Modules/models/BaseModule";
import { ModuleTypesEnum } from "@/includes/logic/Modules/types/types";
import { Type } from "class-transformer";
import MessageEditorWithMediaData
  from "piramis-base-components/src/components/NewMessageEditors/NewMessageEditorWithMedia/types";
import { PunishActions } from "@/includes/logic/Modules/models/PunishActions/types";

export default class VotebanHandlerModule extends BaseModule {
  type: ModuleTypesEnum = ModuleTypesEnum.SendWakeupMessageModule;

  @Type(() => VotebanHandlerModuleParams)
  params!: VotebanHandlerModuleParams;
}

class VotebanHandlerModuleParams {
  timeout!: number
  limit!: number

  @Type(() => MessageEditorWithMediaData)
  message!: Array<MessageEditorWithMediaData>

  punish_action!: PunishActions
  actor_punish_action!: PunishActions

  punish_button!: string
  forgive_button!: string

  protection!: {
    count: number
    interval: number
  } | null
}
