import { ModuleTypesEnum } from "@/includes/logic/Modules/types/types";
import FilterModule from "@/includes/logic/Modules/models/filters/FilterModule";

import { Type } from "class-transformer";

export default class CensureFilterHandlerModule extends FilterModule {
  type: ModuleTypesEnum = ModuleTypesEnum.CensureFilterHandlerModule;

  @Type(() => CensureWordFilterHandlerModuleParams)
  params!: CensureWordFilterHandlerModuleParams
}

class CensureWordFilterHandlerModuleParams {
    automatic_obscene_detection!: boolean

    obscene_words_dict!: Array<string>
    exclude_obscene_words!: Array<string>
}
