import { ModuleTypesEnum } from "@/includes/logic/Modules/types/types";
import FilterModule from "@/includes/logic/Modules/models/filters/FilterModule";

import { RawChipItem } from "piramis-base-components/src/components/TypedChips/typedChips.types";
import { BlackWhiteListItem } from "piramis-base-components/src/components/BlackWhiteList/types";

import { Type } from "class-transformer";

export default class ReferralFilterHandlerModule extends FilterModule {
  type: ModuleTypesEnum = ModuleTypesEnum.ReferralFilterHandlerModule;

  @Type(() => ReferralFilterHandlerParams)
  params!: ReferralFilterHandlerParams
}

class ReferralFilterHandlerParams {
  items!: BlackWhiteListItem<RawChipItem>
}
