import ConfigCondition from "@/includes/logic/Modules/models/ConfigCondition";
import { ModuleConditionTypeEnum } from "@/includes/logic/Modules/types/types";
import { BoostsSourceEnum } from "@/includes/logic/Modules/models/conditions/BoostsCondition/types";
import BindChannelApi from "@/includes/Api/BindChannel.api";
import store from "@/store/store";
import { errorNotification } from "@/includes/NotificationService";

export default class BoostsCondition extends ConfigCondition {
  type: ModuleConditionTypeEnum = ModuleConditionTypeEnum.Boosts;
  source: BoostsSourceEnum = BoostsSourceEnum.Group;

  /** if source === BoostsSourceEnum.Custom **/
  custom: number | null = null
  required = true

  async validate(): Promise<boolean> {
    if (this.source === BoostsSourceEnum.Custom && this.custom) {
        return BindChannelApi.checkBindChannel('tg', {
          chat_id: store.getters.chatId,
          channel: this.custom.toString()
        })
            .then(res => true)
            .catch(err => {
              errorNotification(err)

              return false
            })
    }

    return true
  }
}
