export enum ReputationCalculationModeEnum {
  Standard = 'Standard',
  Random = 'Random',
  Complex = 'Complex'
}

export enum ReputationModeEnum {
  All = 'All',
  Admins = 'Admins',
  Separated = 'Separated'
}

export enum ActorTypeEnum {
  All = 'All',
  Admin = 'Admin',
  User = 'User'
}

export enum ReactionEmojiEnum {
  ThumbsUp = 'ThumbsUp',
  ThumbsDown = 'ThumbsDown',
  RedHeart = 'RedHeart',
  Fire = 'Fire',
  SmilingFaceWhitHearts = 'SmilingFaceWhitHearts',
  ClappingHands = 'ClappingHands',
  BeamingFaceWithSmilingEyes = 'BeamingFaceWithSmilingEyes',
  ThinkingFace = 'ThinkingFace',
  ExplodingHead = 'ExplodingHead',
  FaceScreamingInFear = 'FaceScreamingInFear',
  FaceWithSymbolsOnMouth = 'FaceWithSymbolsOnMouth',
  CryingFace = 'CryingFace',
  PartyPopper = 'PartyPopper',
  StarStruck = 'StarStruck',
  FaceVomiting = 'FaceVomiting',
  PileOfPoo = 'PileOfPoo',
  FoldedHands = 'FoldedHands',
  OkHand = 'OkHand',
  Dove = 'Dove',
  ClownFace = 'ClownFace',
  HandOnMouth = 'HandOnMouth',
  WoozyFace = 'WoozyFace',
  SmilingFaceWithHeartEyes = 'SmilingFaceWithHeartEyes',
  SpoutingWhale = 'SpoutingWhale',
  FlameHeart = 'FlameHeart',
  NewMoonFace = 'NewMoonFace',
  HotDog = 'HotDog',
  HundredPoints = 'HundredPoints',
  RollingOnTheFloorLaughing = 'RollingOnTheFloorLaughing',
  HighVoltage = 'HighVoltage',
  Banana = 'Banana',
  Trophy = 'Trophy',
  BrokenHeart = 'BrokenHeart',
  Emoji = 'Emoji',
  FaceWithRaisedEyebrow = 'FaceWithRaisedEyebrow',
  Strawberry = 'Strawberry',
  BottleWithPoppingCork = 'BottleWithPoppingCork',
  KissMark = 'KissMark',
  MiddleFinger = 'MiddleFinger',
  SmilingFaceWithHorns = 'SmilingFaceWithHorns',
  SleepingFace = 'SleepingFace',
  LoudlyCryingFace = 'LoudlyCryingFace',
  NerdFace = 'NerdFace',
  Ghost = 'Ghost',
  ManTechnologist = 'ManTechnologist',
  Eyes = 'Eyes',
  JackOLantern = 'JackOLantern',
  SeeNoEvilMonkey = 'SeeNoEvilMonkey',
  SmilingFaceWithHalo = 'SmilingFaceWithHalo',
  FearfulFace = 'FearfulFace',
  Handshake = 'Handshake',
  WritingHand = 'WritingHand',
  HuggingFace = 'HuggingFace',
  Honor = 'Honor',
  SantaClaus = 'SantaClaus',
  ChristmasTree = 'ChristmasTree',
  Snowman = 'Snowman',
  NailPolish = 'NailPolish',
  ZanyFace = 'ZanyFace',
  Moai = 'Moai',
  CoolButton = 'CoolButton',
  HeartWithArrow = 'HeartWithArrow',
  HearNoEvilMonkey = 'HearNoEvilMonkey',
  Unicorn = 'Unicorn',
  FaceBlowingKiss = 'FaceBlowingKiss',
  Pill = 'Pill',
  SpeakNoEvilMonkey = 'SpeakNoEvilMonkey',
  SmilingFaceWithSunglasses = 'SmilingFaceWithSunglasses',
  AlienMonster = 'AlienMonster',
  ManShrugging = 'ManShrugging',
  PersonShrugging = 'PersonShrugging',
  WomanShrugging = 'WomanShrugging',
  PoutingFace = 'PoutingFace',
}
