import BaseModule from "@/includes/logic/Modules/models/BaseModule";
import { ModuleTypesEnum } from "@/includes/logic/Modules/types/types";

import { BlackWhiteListItem } from "piramis-base-components/src/components/BlackWhiteList/types";

import { Type } from "class-transformer";

export default class SpamReactionHandlerModule extends BaseModule {
  type: ModuleTypesEnum = ModuleTypesEnum.SpamReactionHandlerModule;

  @Type(() => SpamReactionHandlerModuleParams)
  params!: SpamReactionHandlerModuleParams;
}

class SpamReactionHandlerModuleParams {
  handle_channel!: boolean
  channel_max_subscribers!: number
  channel_whitelist!: BlackWhiteListItem<number>

  spam_reaction_count!: number
  spam_reaction_interval!: number

  old_message_reaction_count!: number
  old_message_interval!: number
}
