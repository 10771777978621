import { ModuleGroupEnum, ModuleIcon } from "@/includes/logic/Modules/types/types";
import ModuleBuilder from "@/includes/logic/Modules/Builders/ModuleBuilder";
import EntertainmentHandlerModule
  from "@/includes/logic/Modules/models/modules/EntertainmentHandlerModule/EntertainmentHandlerModule";

export default class EntertainmentHandlerModuleBuilder extends ModuleBuilder {
  model!: EntertainmentHandlerModule;

  view: ModuleBuilder['view'] = () => import('../../../../../../components/Modules/modules/Activity/EntertainmentHandlerModuleView.vue')
  group: ModuleGroupEnum = ModuleGroupEnum.ActivityBoost

  icon: ModuleIcon = {
    type: "Pack",
    pack: 'material-icons',
    name: 'videogame_asset',
  }
}

