import BaseModule from "@/includes/logic/Modules/models/BaseModule";
import { ModuleTypesEnum } from "@/includes/logic/Modules/types/types";

import MessageEditorWithMediaData
  from "piramis-base-components/src/components/NewMessageEditors/NewMessageEditorWithMedia/types";

import { Type } from "class-transformer";

export default class RanksHandlerModule extends BaseModule {
  type: ModuleTypesEnum = ModuleTypesEnum.RanksHandlerModule;

  @Type(() => RanksHandlerModule)
  params!: RanksHandlerModuleParams;
}

class RanksHandlerModuleParams {
  user_ranks!: Array<{title: string, reputation: number, xp: number}>

  user_ranks_by_reputation!: boolean

  user_ranks_by_xp!: boolean

  allow_to_reduce_rank!: boolean

  @Type(() => MessageEditorWithMediaData)
  rank_action_message!: Array<MessageEditorWithMediaData>
}
