import Api from "@/includes/logic/Api";

import { InvoiceInfo, InvoiceInfoWithTargets } from "piramis-base-components/src/plugins/AdminTemplate/includes/types/Shared/InvoiceInfo.types";

export default class RecurringInvoicesApi {
  static async getRecurringInvoices(): Promise<{ data: { invoices: Array<InvoiceInfoWithTargets> } }> {
    return Api.sendRequest('tg', 'getrecurringinvoices', {})
  }

  static async deleteRecurringInvoice(invoice: InvoiceInfo['invoice_number']): Promise<any> {
    return Api.sendRequest('tg', 'deleterecurringinvoice', { invoice })
  }
}
