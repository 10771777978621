import { ModuleGroupEnum, ModuleIcon } from "@/includes/logic/Modules/types/types";
import ModuleBuilder from "@/includes/logic/Modules/Builders/ModuleBuilder";

import SilentInviteHandlerModule from "./SilentInviteHandlerModule";

export default class SilentInviteHandlerModuleBuilder extends ModuleBuilder {
  model!: SilentInviteHandlerModule;

  group: ModuleGroupEnum = ModuleGroupEnum.UsersModeration
  view = null

  icon: ModuleIcon = {
    type: "Pack",
    name: 'do_not_touch',
    pack: "material-icons",
    iconStyle: 'outlined'
  }
}

