import VuTriggers          from "../../includes/logic/VuTriggers";
import { errorNotification } from "@/includes/NotificationService";

export default {
	updateTriggers({ commit, state }) {
	  if (state.triggersState.loadingTriggerPromise === null) {
	    const loadingTriggerPromise = new Promise((resolve, reject) => {
	      VuTriggers.getTriggers()
			.then(res => {
			  commit("updateTriggers", res);

			  VuTriggers.updateFrontendTriggers();

			  resolve();
			})
			.catch(err => {
			  errorNotification(err);
			  reject(err);

			  commit('setObjectField', {
			    object    : state.triggersState,
			    objectPath: "frontendTriggers",
			    value     : [],
			  })
			})
			.finally(() => {
			  commit('setObjectField', {
			    object    : state.triggersState,
			    objectPath: "loadingTriggerPromise",
			    value     : null,
			  })
			});
	    });

	      commit('setObjectField', {
		object    : state.triggersState,
		objectPath: "loadingTriggerPromise",
		value     : loadingTriggerPromise,
	      })

	      return loadingTriggerPromise
	  }

	  return state.triggersState.loadingTriggerPromise
	},
};
