import { ModuleGroupEnum, ModuleIcon } from "@/includes/logic/Modules/types/types";
import ModuleBuilder from "@/includes/logic/Modules/Builders/ModuleBuilder";

import AnyMessageFilterHandlerModule from "./AnyMessageFilterHandlerModule";

export default class AnyMessageFilterHandlerModuleBuilder extends ModuleBuilder {
  model!: AnyMessageFilterHandlerModule;

  group: ModuleGroupEnum = ModuleGroupEnum.MessagesModeration

  icon: ModuleIcon = {
    type: "Pack",
    pack: 'material-icons',
    name: "surround_sound",
    color: "rgba(var(--a-primary), 1)"
  };

  view =  () => import('@/components/Modules/modules/Filters/AnyMessageFilterHandlerModuleView.vue')

  aliases = [ 'фильтры', 'удалять', 'удалить', 'всех сообщений', 'любых сообщений' ]
}
