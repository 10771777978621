import { BaseNetworkData, NetworkConfig } from "@/includes/types/networks";
import { RawTriggerData } from "@/components/TriggerSetup/logic/types/types";
import { AxiosError } from "axios";

export type NetworksState = {
  networks: Array<BaseNetworkData> | null
  error: Error | null
  currentNetwork: NetworkConfig | null
  triggers: {
    triggerToCopy: RawTriggerData | null
    triggers: Array<RawTriggerData> | null
    loadingTriggerPromise: Promise<Array<RawTriggerData>> | null,
  }
}

const networksState: NetworksState = {
  networks: null,
  error: null,
  currentNetwork: null,
  triggers: {
    triggerToCopy: null,
    triggers: null,
    loadingTriggerPromise: null
  }
};

export default networksState
