import { ModuleGroupEnum, ModuleIcon } from "@/includes/logic/Modules/types/types";
import ModuleBuilder from "@/includes/logic/Modules/Builders/ModuleBuilder";
import NewUsersLimitHandlerModule from "./NewUsersLimitHandlerModule";

export default class NewUsersLimitHandlerModuleBuilder extends ModuleBuilder {
  model!: NewUsersLimitHandlerModule;

  view: ModuleBuilder['view'] = () => import('../../../../../../components/Modules/modules/Facecontrol/NewUsersLimitHandlerModuleView.vue')
  group: ModuleGroupEnum = ModuleGroupEnum.UsersModeration

  icon: ModuleIcon = {
    type: "Pack",
    pack: 'material-icons',
    name: 'do_not_disturb_on',
    iconStyle: 'outlined'
  }
}

