import { ModuleGroupEnum, ModuleIcon } from "@/includes/logic/Modules/types/types";
import ModuleBuilder from "@/includes/logic/Modules/Builders/ModuleBuilder";
import BotGuardHandlerModule from "./BotGuardHandlerModule";

export default class BotGuardHandlerModuleBuilder extends ModuleBuilder {
  model!: BotGuardHandlerModule;

  view: ModuleBuilder['view'] = () => import('../../../../../../components/Modules/modules/Facecontrol/BotGuardHandlerModuleView.vue')
  group: ModuleGroupEnum = ModuleGroupEnum.UsersModeration

  icon: ModuleIcon = {
    type: "Pack",
    pack: 'material-icons',
    name: 'smart_toy',
  }
}
