import { ModuleGroupEnum, ModuleIcon } from "@/includes/logic/Modules/types/types";
import ModuleBuilder from "@/includes/logic/Modules/Builders/ModuleBuilder";

import ChannelMessageFilterHandlerModule from "./ChannelMessageFilterHandlerModule";

export default class ChannelMessageFilterHandlerModuleBuilder extends ModuleBuilder {
  model!: ChannelMessageFilterHandlerModule;

  group: ModuleGroupEnum = ModuleGroupEnum.MessagesModeration
  icon: ModuleIcon = {
    type: "Pack",
    pack: 'material-icons',
    name: "sensors",
    color: "rgba(var(--a-primary), 1)"
  };

  view = () => import('@/components/Modules/modules/Filters/ChannelMessageFilterHandlerModuleView.vue')

  aliases = [ 'фильтры', 'удалять', 'удалить', ]

}
