import {
  IBaseModule,
  ModuleTypesEnum,
} from "@/includes/logic/Modules/types/types";
import ModuleConfig from "./ModuleConfig";

import { Exclude, Type } from "class-transformer";
import { isEmpty, snakeCase } from "lodash";

export default abstract class BaseModule implements IBaseModule {
  abstract type: ModuleTypesEnum

  guid!: string

  @Type(() => ModuleConfig)
  config!: ModuleConfig

  abstract params?: Record<string, any>

  @Exclude()
  get snakeCaseType() {
    return snakeCase(this.type)
  }

  @Exclude()
  get titleKey() {
    return `module_${ this.snakeCaseType }_title`
  }

  @Exclude()
  get descriptionKey() {
    return `module_${ this.snakeCaseType }_description`
  }

  @Exclude()
  get pageMiniHelpMessageKey() {
    return `module_${ this.snakeCaseType }_page_mini_help_message`
  }

  @Exclude()
  get pageHelpMessageKey() {
    return `module_${ this.snakeCaseType }_page_help_message`
  }

  @Exclude()
  get miniDescriptionKey() {
    return `module_${ this.snakeCaseType }_mini_description`
  }
}

