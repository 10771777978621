import tokenId from "@/includes/utils/TokenId";

export default {
	topicsStructureIfForum: (state) => (boardTokenId, targetId) => {
		const board = state.postsPlanner.boards.find(b => b.board.token_id === Number.parseInt(boardTokenId));

		if(board) {
			const target = board.targets.find(t => t.id === targetId);

			if("topics" in target) {
				return [];
			} else {
				return null;
			}
		} else {
			throw "Board not exists";
		}
	},
	topicsIfForum         : (state) => (boardTokenId, targetId) => {
		const board = state.postsPlanner.boards.find(b => b.board.token_id === Number.parseInt(boardTokenId));

		if(board) {
			const target = board.targets.find(t => t.id === targetId);

			if("topics" in target) {
				return target.topics.map(t => ({
					label: t.title,
					value: t.id,
				}));
			} else {
				return undefined;
			}
		} else {
			throw "Board not exists";
		}
	},
	currentBoardKey: (state) => {
		const board = state.postsPlanner.currentBoard

		if (board) {
			return tokenId.create(board.board.token_id, board.board.token_code)
		}
	},
	isCurrentBoardBrand(state, getters) {
		const KEEPER_BOT_CODE = '.default'

		return !getters.currentBoardKey.includes(KEEPER_BOT_CODE)
	}
};
