import buttonView from './TriggerCallButton.vue'
import { ProjectButtonsEnum } from "@/components/ProjectButtons/NewButtons/ProjectButtonsEnum";
import setupView from './TriggerCallButtonSetupView.vue'

import BaseButtonBuilder, { BaseButtonViewData }  from 'piramis-base-components/src/components/NewMessageEditors/NewMessageEditorWithMedia/ButtonsConstructor/Buttons/index'
import { BaseButton, } from 'piramis-base-components/src/components/NewMessageEditors/NewMessageEditorWithMedia/ButtonsConstructor/Buttons/types'
import { SelectOption } from "piramis-base-components/src/logic/types";

import { Guid } from "guid-typescript";
import { plainToInstance } from "class-transformer";
import i18n from "vue-i18n";

export interface TriggerCallButtonProps {
  triggersSelectOptions?: () => Promise<Array<SelectOption>>
}

export default class TriggerCallButtonBuilder extends BaseButtonBuilder {
  type = ProjectButtonsEnum.TriggerCall
  titleKey = ProjectButtonsEnum.TriggerCall

  setupView = setupView
  buttonView = buttonView

  data = new TriggerCallButtonData(this.$i18n.t('initial_text_message_editor_buttons').toString())

  declare buttonProps: TriggerCallButtonProps

  constructor(i18n: i18n, triggersSelectOptions: TriggerCallButtonProps['triggersSelectOptions']) {
    super(i18n, { triggersSelectOptions })
  }

    icon = '⚙'

  getData(button?: TriggerCallButton): TriggerCallButtonData {
    return new TriggerCallButtonData(button ? button : this.$i18n.t('initial_text_message_editor_buttons').toString())
  }

  create(): TriggerCallButton {
    return plainToInstance(TriggerCallButton, {
      ...this.data,
      type: ProjectButtonsEnum.TriggerCall,
      guid: Guid.create().toString()
    })
  }
}

export class TriggerCallButtonData implements BaseButtonViewData {
  code!: number
  text!: string

  constructor(data: TriggerCallButton | string) {
    if(data instanceof TriggerCallButton || typeof data === 'object') {
      const { text, code } = data

      this.text = text
      this.code = code
    } else {
      this.text = data
    }
  }
}

export class TriggerCallButton implements BaseButton {
  text!: string
  code!: number
  type!: ProjectButtonsEnum.TriggerCall
  guid!: string
}
