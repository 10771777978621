import { ModuleGroupEnum, ModuleIcon } from "@/includes/logic/Modules/types/types";
import ModuleBuilder from "@/includes/logic/Modules/Builders/ModuleBuilder";

import DiceFilterHandlerModule from "./DiceFilterHandlerModule";

export default class DiceFilterHandlerModuleBuilder extends ModuleBuilder {
  model!: DiceFilterHandlerModule;

  group: ModuleGroupEnum = ModuleGroupEnum.MessagesModeration
  icon: ModuleIcon = {
    type: "Pack",
    pack: 'material-icons',
    name: "casino",
    color: "rgba(var(--a-dark), 1)"
  };
  view =  () => import('@/components/Modules/modules/Filters/DiceFilterHandlerModuleView.vue')

  aliases = [ 'фильтры', 'удалять', 'удалить', ]
}
