import { BaseNetworkData } from "@/includes/types/networks";
import { Commit } from "vuex";

export default {
  setCurrentNetwork({ commit }: { commit: Commit }, network: BaseNetworkData) {
    commit('SET_CURRENT_NETWORK', network)
  },
  pushNetworkError({ commit }: { commit: Commit }, error: Error | null) {
    commit('SET_ERROR', error)
  },
};
