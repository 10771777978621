export default {
	set_boards_to_store(state, boards) {
		state.postsPlanner.boards = boards;
	},
	set_current_board(state, board) {
		state.postsPlanner.currentBoard = board
	},
	clear_current_board(state) {
		state.postsPlanner.currentBoard = null
	},
};
