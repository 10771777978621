export enum BaseViewActionType {
  New = 'new',
  Edit = 'edit',
  Watch = 'watch',
}

export enum AdminOrAllPermissions {
  None = 'None',
  Admins = 'Admins',
  All = 'All',
}

