import buttonView from './ActionButton.vue'
import setupView from './ActionButtonSetupView.vue'
import { ProjectButtonsEnum } from "@/components/ProjectButtons/NewButtons/ProjectButtonsEnum";

import BaseButtonBuilder, { BaseButtonViewData }  from 'piramis-base-components/src/components/NewMessageEditors/NewMessageEditorWithMedia/ButtonsConstructor/Buttons/index'
import { BaseButton, } from 'piramis-base-components/src/components/NewMessageEditors/NewMessageEditorWithMedia/ButtonsConstructor/Buttons/types'

import { Guid } from "guid-typescript";
import { plainToInstance } from "class-transformer";
import i18n from "vue-i18n";

export default class ActionButtonBuilder extends BaseButtonBuilder {
    type = ProjectButtonsEnum.Action
    titleKey = ProjectButtonsEnum.Action

    setupView = setupView
    buttonView = buttonView

    initText = this.$i18n.t('initial_text_message_editor_buttons').toString()

    data = new ActionButtonData(this.initText)

    icon = '🔓'

    constructor(i18n: i18n, initButtonText?: string) {
      super(i18n)

      if (initButtonText) {
        this.initText = initButtonText
      }
    }

  getData(button?: ActionButton): ActionButtonData {
    return new ActionButtonData(button ? button : this.initText)
  }

  create(): ActionButton {
    return plainToInstance(ActionButton, {
      ...this.data,
      type: ProjectButtonsEnum.Action,
      guid: Guid.create().toString()
    })
  }
}

export class ActionButtonData implements BaseButtonViewData {
  action = 1
  text = ''

  constructor(data: ActionButton | string) {
    if(data instanceof ActionButton || typeof data === 'object') {
      const { text } = data

      this.text = text
    } else {
      this.text = data
    }
  }
}

export class ActionButton implements BaseButton {
  text!: string
  action!: number
  type!: ProjectButtonsEnum.Action
  guid!: string
}
