import { ModuleTypesEnum } from "@/includes/logic/Modules/types/types";
import FilterModule from "@/includes/logic/Modules/models/filters/FilterModule";
import { Type } from "class-transformer";

export enum EmojiTypeEnum {
  All = 'All',
  Custom = 'Custom',
  Standard = 'Standard'
}

export default class EmojiFilterHandlerModule extends FilterModule {
  type: ModuleTypesEnum = ModuleTypesEnum.EmojiFilterHandlerModule;

  @Type(() => EmojiFilterHandlerModuleParams)
  params!: EmojiFilterHandlerModuleParams
}

class EmojiFilterHandlerModuleParams {
  emoji_any_message_max_smiles!: number
  emoji_only_smiles_message_max!: number
  emoji_type!: EmojiTypeEnum
}
