import { SelectOption } from "piramis-base-components/src/logic/types";

export default {
  formsSelectOptions: (state:any): Array<SelectOption> => {
    const forms = state.chatState.questionnaire.forms

    if (forms === null || !forms.length) {
      return []
    } else {
      return forms.map((f:any) => ({
        label: f.name,
        value: f.guid
      }))
    }
  }
}
