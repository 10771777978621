import { ModuleGroupEnum, ModuleIcon } from "@/includes/logic/Modules/types/types";
import ModuleBuilder from "@/includes/logic/Modules/Builders/ModuleBuilder";

import { ReputationCalculationModeEnum } from "./types";
import ReputationMessageHandlerModule from "./ReputationMessageHandlerModule";
import { ComplexReputationCalculationMode, RandomReputationCalculationMode, StandartReputationCalculationMode } from "./ReputationCalculationMode";

export default class ReputationMessageHandlerModuleBuilder extends ModuleBuilder {
  model!: ReputationMessageHandlerModule;

  view: ModuleBuilder['view'] = () => import('../../../../../../components/Modules/modules/Reputation/ReputationMessageHandlerModuleView.vue')
  group: ModuleGroupEnum = ModuleGroupEnum.ActivityBoost

  icon: ModuleIcon = {
    type: "Pack",
    pack: 'material-icons',
    name: 'stars',
    iconStyle: 'outlined'
  }

  aliases = [ 'повышение активности', 'активность чата', 'активность', 'очки', 'бал' ]

  get repCalcMode() {
    return this.model.params.reputation_calculation_mode.type
  }

  set repCalcMode(value: ReputationCalculationModeEnum) {
    switch (value) {
      case ReputationCalculationModeEnum.Standard:
        this.model.params.reputation_calculation_mode = new StandartReputationCalculationMode()
        break;
      case ReputationCalculationModeEnum.Complex:
        this.model.params.reputation_calculation_mode = new ComplexReputationCalculationMode()
        break;
      case ReputationCalculationModeEnum.Random:
        this.model.params.reputation_calculation_mode = new RandomReputationCalculationMode()
        break;
    }
  }

  get positiveRepAdminMessage() {
    return this.model.params.positive.admin_message
  }

  set positiveRepAdminMessage(value) {
    this.model.params.positive.admin_message = value
  }

  get negativeRepAdminMessage() {
    return this.model.params.negative.admin_message
  }

  set negativeRepAdminMessage(value) {
    this.model.params.negative.admin_message = value
  }
}

