import BaseModule from "@/includes/logic/Modules/models/BaseModule";
import { ModuleTypesEnum } from "@/includes/logic/Modules/types/types";
import { AdminOrAllPermissions } from "@/includes/types/Enums";

import { Type } from "class-transformer";

export default class ChatCryptoCommandHandlerModule extends BaseModule {
  type: ModuleTypesEnum = ModuleTypesEnum.ChatCryptoCommandHandlerModule;

  @Type(() => ChatCryptoCommandHandlerModuleParams)
  params!: ChatCryptoCommandHandlerModuleParams;
}

class ChatCryptoCommandHandlerModuleParams {
  crypto_info_permissions!: AdminOrAllPermissions
  crypto_charts_permissions!: AdminOrAllPermissions
  exchange_provider!: string
}
