import { ModuleGroupEnum, ModuleIcon } from "@/includes/logic/Modules/types/types";
import ModuleBuilder from "@/includes/logic/Modules/Builders/ModuleBuilder";
import FacecontrolHandlerModule from "./FacecontrolHandlerModule";

export default class FacecontrolHandlerModuleBuilder extends ModuleBuilder {
  model!: FacecontrolHandlerModule;

  view: ModuleBuilder['view'] = () => import('../../../../../../components/Modules/modules/Facecontrol/FacecontrolHandlerModuleView.vue')
  group: ModuleGroupEnum = ModuleGroupEnum.UsersModeration

  icon: ModuleIcon = {
    type: "Pack",
    pack: "material-icons",
    name: 'back_hand',
    iconStyle: "outlined"
  }
}

