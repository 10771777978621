import BaseModule from "@/includes/logic/Modules/models/BaseModule";
import { ModuleTypesEnum } from "@/includes/logic/Modules/types/types";

import MessageEditorWithMediaData
  from "piramis-base-components/src/components/NewMessageEditors/NewMessageEditorWithMedia/types";

import { Exclude, Type } from "class-transformer";

export default class ModerateCommandHandlerModule extends BaseModule {
  type: ModuleTypesEnum = ModuleTypesEnum.ModerateCommandHandlerModule;

  @Type(() => ModerateCommandHandlerModuleParams)
  params!: ModerateCommandHandlerModuleParams;
}

class ModerateCommandHandlerModuleParams {
  kick_command_remove!: boolean
  kick_command_reply!: boolean

  @Type(() => MessageEditorWithMediaData)
  kick_command_text!: Array<MessageEditorWithMediaData>

  @Exclude()
  ban_command_remove!:boolean
  ban_command_remove_action!: keyof typeof BanActionTypesEnum
  ban_command_reply!: boolean

  @Type(() => MessageEditorWithMediaData)
  ban_command_text!: MessageEditorWithMediaData

  mute_command_remove!: boolean
  mute_command_reply!: boolean

  @Type(() => MessageEditorWithMediaData)
  mute_command_text!: Array<MessageEditorWithMediaData>

  journal_enabled!: boolean
}

export enum BanActionTypesEnum {
  None = 'None',
  Reply = 'Reply',
  All = 'All'
}
