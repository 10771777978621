

























import ScriptLoader from "./includes/helpers/ScriptLoader";
import i18n from "@/i18n/i18n";
import { ActionButton } from '@/components/ProjectButtons/NewButtons/Action'
import { TriggerCallButton } from '@/components/ProjectButtons/NewButtons/TriggerCall'
import store from '@/store/store'
import { BaseChatInfo } from '@/includes/types/Chat/types'
import { ModuleManagerState } from "@/includes/logic/Modules/ModulesManager";

import { AdditionalReplacerData, EntityTypes } from 'piramis-base-components/src/components/SelectEntityWizard/includes/types'
import {
  hasMatchedRouteParams,
  initAdditionalReplacer,
} from 'piramis-base-components/src/components/SelectEntityWizard/includes/helpers'
import SelectEntityWizard from 'piramis-base-components/src/components/SelectEntityWizard/SelectEntityWizard.vue'
import MessageEditorModelButtonsFactory
  from 'piramis-base-components/src/components/NewMessageEditors/NewMessageEditorWithMedia/ButtonsConstructor/logic/MessageEditorModelButtonsFactory'

import Vue from "vue";
import axios from 'axios'
import { isEqual } from "lodash";
import ruRu from "ant-design-vue/lib/locale-provider/ru_RU";
import enUS from 'ant-design-vue/lib/locale-provider/en_US'
import { Component } from 'vue-property-decorator'
import moment from "moment";
import NewWizard from "@/components/Wizard/NewWizard.vue";
import { chatWizardConfig } from "@/components/Wizard/ChatWizardConfig";

@Component({
  computed: {
    chatWizardConfig() {
      return chatWizardConfig
    }
  },
  components: {
    NewWizard,
    SelectEntityWizard,
  }
})
export default class App extends Vue {
  translationsLoaded = false

  get isChatWizardOpen() {
    return this.$store.state.chatState.isChatWizardOpen
  }

  set isChatWizardOpen(val) {
    this.$store.commit('IS_CHAT_WIZARD_OPEN', val)
  }

  map = {
    [EntityTypes.CHAT_ID]: () => {
      if (!this.$store.state.chatState.chatsInfo?.chats) {
        return this.$store.dispatch('requestChatsInfo')
          .then(() => {
            return (store.getters.sortedVisibleChatsInfos as Array<BaseChatInfo>)
              .map(c => ({ id: c.chat_id, title: c.group_title, avatar: c.group_photo }))
          })
      } else {
        return (store.getters.sortedVisibleChatsInfos as Array<BaseChatInfo>)
          .map(c => ({ id: c.chat_id, title: c.group_title, avatar: c.group_photo }))
      }
    },
    [EntityTypes.BOT_ID]: () => {
      return this.$store.dispatch('requestPostBoards')
        .then(() => {
          return this.$store.state.postsPlanner.boards
            .map((b:any) => ({ id: b.board.token_id, title: b.title, avatar: b.avatar }))
        })
    },
  }

  get containsSelectConstants():boolean {
    return hasMatchedRouteParams(this.$route)
  }

  get currentLocale() {
    moment.locale(this.$i18n.locale)

    if (this.$i18n.locale === 'ru') {
      return ruRu
    }

    if (this.$i18n.locale === 'en') {
      return enUS
    }

    return ruRu
  }

  beforeWindowUnload(e:BeforeUnloadEvent) {
    if (this.$store.state.chatState.chat && this.$store.state.chatState.backupConfig !== null) {
      if (!isEqual(this.$store.state.chatState.chat.config, this.$store.state.chatState.backupConfig.config)) {
        return e.returnValue = this.$t('config_not_saved_alert').toString();
      }
    }
  }

  beforeDestroy() {
    window.removeEventListener('beforeunload', this.beforeWindowUnload)
  }

  initChatKeeperButtons() {
    MessageEditorModelButtonsFactory.SetModelFactory('Action', ActionButton)
    MessageEditorModelButtonsFactory.SetModelFactory('TriggerCall', TriggerCallButton)
  }

  initAnchorReplacer() {
    const replacerFn: AdditionalReplacerData['replacerFn'] = (newRoute, route) => {
      if (route.params.moduleGuidOrType && ModuleManagerState?.currentModule) {
        newRoute = newRoute.replace(`/module/${ route.params.moduleGuidOrType }`, `/module/${ ModuleManagerState.currentModule.model.type }`)
      }

      return newRoute
    }

    initAdditionalReplacer({ replacerFn: replacerFn, onWizardFinish: (resultPath, selectMap, route) => {
      const gotoChat = () => this.$router.replace({ path: resultPath, query: {} })
      const moduleQuery = this.$route.params.moduleGuidOrType?.toString()

      if (moduleQuery) {
        const id = selectMap.find(m => m.type === EntityTypes.CHAT_ID)?.selectedId

        if (id) {
          this.$store.dispatch('requestChatConfig', { id })
            .then(() => {
              this.$router.replace({ path: resultPath })
            })
        } else {
          gotoChat()
        }
      } else {
        gotoChat()
      }
    }
    })
  }

  loadTranslations() {
    axios.get(`/translations/common.json`).then(({ data }) => {

      // TODO: FIX IT Нужно в одном месте хранить переводы

      i18n.mergeLocaleMessage('ru', data['ru'])
      i18n.mergeLocaleMessage('en', data['en'])

      this.$i18n.mergeLocaleMessage('ru', data['ru'])
      this.$i18n.mergeLocaleMessage('en', data['en'])

      this.translationsLoaded = true;

    }).catch(console.error)
  }

  googleTag() {
    // Global site tag (gtag.js) - Google Statistic
    new ScriptLoader([ 'https://www.googletagmanager.com/gtag/js?id=UA-154663624-1' ], () => {
      (window as any).dataLayer = (window as any).dataLayer || [];

      function gtag(..._:Array<any>) {
        (window as any).dataLayer.push(arguments);
      }

      gtag("js", new Date());
      gtag("config", "UA-154663624-1");
    })
      .require()
  }

  mounted() {
    this.initChatKeeperButtons()

    window.addEventListener('beforeunload', this.beforeWindowUnload)

    this.googleTag()

    this.loadTranslations()

    this.initAnchorReplacer()
  }
}
