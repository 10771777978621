import { ModuleTypesEnum } from "@/includes/logic/Modules/types/types";
import FilterModule from "@/includes/logic/Modules/models/filters/FilterModule";

import { Type } from "class-transformer";

export default class StopWordFilterHandlerModule extends FilterModule {
  type: ModuleTypesEnum = ModuleTypesEnum.StopWordFilterHandlerModule;

  @Type(() => StopWordFilterHandlerModuleParams)
  params!: StopWordFilterHandlerModuleParams;
}

class StopWordFilterHandlerModuleParams {
  stop_word_handle_modified!: boolean

  stop_words_search_in_usernames!: boolean
  stop_words!: Array<string>
}
