import { RootState } from "@/store/state";

const getters = {
  readonlyNetwork: (state: RootState) => {
    if (state.networksState.currentNetwork) {
      return state.networksState.currentNetwork.read_only;
    }

    return true
  },
};

export default getters;
