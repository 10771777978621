import { ModuleGroupEnum, ModuleIcon } from "@/includes/logic/Modules/types/types";
import ModuleBuilder from "@/includes/logic/Modules/Builders/ModuleBuilder";
import InactiveKickHandlerModule
  from "@/includes/logic/Modules/models/modules/InactiveKickHandlerModule/InactiveKickHandlerModule";

export default class InactiveKickHandlerModuleBuilder extends ModuleBuilder {
  model!: InactiveKickHandlerModule;

  view: ModuleBuilder['view'] = () => import('../../../../../../components/Modules/modules/Activity/InactiveKickHandlerModuleView.vue')
  group: ModuleGroupEnum = ModuleGroupEnum.ActivityBoost

  icon: ModuleIcon = {
    type: "Pack",
    pack: 'material-icons',
    name: 'auto_delete',
  }
}

