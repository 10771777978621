import { ModuleGroupEnum, ModuleIcon } from "@/includes/logic/Modules/types/types";

import ModuleBuilder from "@/includes/logic/Modules/Builders/ModuleBuilder";
import ModerateCommandHandlerModule from "@/includes/logic/Modules/models/modules/ModerateCommandHandlerModule/ModerateCommandHandlerModule";

export default class ModerateCommandHandlerModuleBuilder extends ModuleBuilder {
  model!: ModerateCommandHandlerModule;

  view: ModuleBuilder['view'] = () => import('../../../../../../components/Modules/modules/ModerateCommandHandlerModuleView.vue')
  group: ModuleGroupEnum = ModuleGroupEnum.ModerationCommands

  icon: ModuleIcon = {
    type: "Pack",
    pack: 'material-icons',
    name: 'code',
  }
}

