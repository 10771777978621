import { IterableItemFactory } from "@/components/TriggerSetup/logic/factories/iterable-item-factory";
import {
  CommandLimit, CommandLimits,
  CommandsLimitLogicState, FrontendCommandLimit,
} from "./types";
import store from "@/store/store";
import { AdminOrAllPermissions } from "@/includes/types/Enums";
import { warningNotification } from "@/includes/NotificationService";
import i18n from "@/i18n/i18n";

import { BlackWhiteListItemType } from "piramis-base-components/src/components/BlackWhiteList/types";

import { cloneDeep } from 'lodash'

const itemFactory = new IterableItemFactory()

//data
export const state: CommandsLimitLogicState = {
  limits: [],
  selectedLimit: null
}

export const rawLimitModal: CommandLimit = {
  commands: [],
  permissions: AdminOrAllPermissions.None,
  count: 0,
  interval: 0,
  users: {
    type: BlackWhiteListItemType.Blacklist,
    items: []
  }
}

export const newFrontendLimitModel = (): FrontendCommandLimit => itemFactory.create(cloneDeep(rawLimitModal))

export const resetSelectedLimit = () => {
  state.selectedLimit = null
}

export const getRawLimits = (): CommandLimits => (store.state.chatState.chat as any).config.command_limits

//predicates
export const isLimitNonePermission = (limit: CommandsLimitLogicState['selectedLimit']) => !!limit && limit.value.permissions === AdminOrAllPermissions.None
export const isLimitAllPermission = (limit: CommandsLimitLogicState['selectedLimit']) => !!limit && limit.value.permissions === AdminOrAllPermissions.All
export const isWhitelistSelected = (limit: CommandsLimitLogicState['selectedLimit']) => !!limit && limit.value.users.type === BlackWhiteListItemType.Whitelist

//data convert
export const setFrontendLimits = (): void => {
  const rawLimits = getRawLimits()

  state.limits = rawLimits.map(l => itemFactory.create(l))
}

export const frontendLimitsToRaw = () => cloneDeep(state.limits).map(l => l.value)

//crud
export const addLocalLimit = (newLimit: FrontendCommandLimit) => {
  state.limits.push(newLimit)

  saveWarning()
}

export const updateLocalLimit = (limitToEdit: FrontendCommandLimit) => {
  const limitIndex = state.limits.findIndex(l => l.guid === limitToEdit.guid)

  state.limits.splice(limitIndex, 1, limitToEdit)

  saveWarning()
}

export const deleteLimit = (guid: string) => {
  state.limits = state.limits.filter(l => l.guid !== guid)

  saveWarning()
}

export const saveLimitsToConfig = () => {
  const backup = cloneDeep((store.state.chatState.chat as any).config.command_limits)

  store.commit('pi/EXEC', {
    'fn': () => {
      (store.state.chatState.chat as any).config.command_limits = frontendLimitsToRaw();
    }
  })

  store.dispatch('saveChatConfig')
      .then(() => setFrontendLimits())
      .catch(() => {
        store.commit('pi/EXEC', {
          'fn': () => {
            (store.state.chatState.chat as any).config.command_limits = backup;
          }
        })
      })
}

//other
const saveWarning = () => warningNotification(i18n.t('save_setting_warn').toString())
