import { RootState } from "@/store/state";
import { NetworkConfig } from "@/includes/types/networks";
import { RawTriggerData } from "@/components/TriggerSetup/logic/types/types";

import { assign } from "lodash";

export default {
  UPDATE_CURRENT_NETWORK(state: RootState, newNetworkData: Partial<NetworkConfig>) {
    assign(state.networksState.currentNetwork, newNetworkData)
  },
  SET_CURRENT_NETWORK(state: RootState, network: NetworkConfig) {
    state.networksState.currentNetwork = network
  },
  CLEAR_CURRENT_NETWORK(state: RootState) {
    state.networksState.currentNetwork = null
  },
  RESET_NETWORK_TRIGGERS(state: RootState) {
    state.networksState.triggers.triggerToCopy = null
    state.networksState.triggers.triggers = null
    state.networksState.triggers.loadingTriggerPromise = null
  },
  SET_TRIGGER_TO_COPY(state: RootState, trigger: RawTriggerData | null) {
    state.networksState.triggers.triggerToCopy = trigger
  },
  SET_NETWORK_TRIGGERS(state: RootState, triggers: Array<RawTriggerData> | null) {
    state.networksState.triggers.triggers = triggers
  },
  REMOVE_NETWORK_TRIGGER(state: RootState, triggerId: RawTriggerData['id']) {
    if (state.networksState.triggers.triggers) {
      state.networksState.triggers.triggers = state.networksState.triggers.triggers.filter(t => t.id !== triggerId)
    }
  },
  SET_ERROR(state: RootState, error: Error | null) {
    state.networksState.error = error
  }
};
