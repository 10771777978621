import BaseModule from "@/includes/logic/Modules/models/BaseModule";
import { ModuleTypesEnum } from "@/includes/logic/Modules/types/types";
import { AdminOrAllPermissions } from "@/includes/types/Enums";

import { Type } from "class-transformer";

export default class AlbumLimitHandlerModule extends BaseModule {
  type: ModuleTypesEnum = ModuleTypesEnum.AlbumLimitHandlerModule;

  @Type(() => AlbumLimitHandlerModuleParams)
  params!: AlbumLimitHandlerModuleParams;
}

class AlbumLimitHandlerModuleParams {
  filter_albums_items_count_limit!: number
  journal_send_album_limit!: boolean
}
