

































import { TriggerCallButtonData, TriggerCallButtonProps } from '@/components/ProjectButtons/NewButtons/TriggerCall/index'

import { UseFields } from 'piramis-base-components/src/components/Pi'
import ModelSetter from 'piramis-base-components/src/Mixins/ModelSetter'

import { Component, Mixins, Prop, VModel } from 'vue-property-decorator'
import { ValidationObserver } from 'vee-validate'

@Component({
  components: {
    ValidationObserver
  }
})
export default class TriggerCallButtonSetupView extends Mixins<UseFields, ModelSetter>(UseFields, ModelSetter) {
  @VModel() model!: TriggerCallButtonData

  @Prop() triggersSelectOptions!: TriggerCallButtonProps['triggersSelectOptions']
}
