import BaseModule from "@/includes/logic/Modules/models/BaseModule";
import { ModuleTypesEnum } from "@/includes/logic/Modules/types/types";

import { Type } from "class-transformer";
import { PunishActions } from "@/includes/logic/Modules/models/PunishActions/types";

export default class EnterBindedChannelHandlerModule extends BaseModule {
  type: ModuleTypesEnum = ModuleTypesEnum.EnterBindedChannelHandlerModule;

  @Type(() => EnterBindedChannelHandlerModuleParams)
  params!: EnterBindedChannelHandlerModuleParams;
}

class EnterBindedChannelHandlerModuleParams {
  ban_action!: PunishActions

  binded_channel_id!: number
  journal!: boolean
}
