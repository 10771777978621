import { ModuleGroupEnum, ModuleIcon } from "@/includes/logic/Modules/types/types";

import ModuleBuilder from "@/includes/logic/Modules/Builders/ModuleBuilder";
import RulesCommandHandlerModule
  from "@/includes/logic/Modules/models/modules/RulesCommandHandlerModule/RulesCommandHandlerModule";

export default class RulesCommandHandlerModuleBuilder extends ModuleBuilder {
  model!: RulesCommandHandlerModule

  view: ModuleBuilder['view'] = () => import('../../../../../../components/Modules/modules/RulesCommandHandlerModuleView.vue')
  group: ModuleGroupEnum = ModuleGroupEnum.ModerationCommands

  icon: ModuleIcon = {
    type: "Pack",
    pack: 'material-icons',
    name: 'rule',
  }
}

