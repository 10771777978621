import { ModuleTypesEnum } from "@/includes/logic/Modules/types/types";
import FilterModule from "@/includes/logic/Modules/models/filters/FilterModule";
import { ToxicFilterModeEnum } from "@/includes/logic/Modules/models/modules/filters/ToxicFilterHandlerModule/types";

import { Type } from "class-transformer";

export default class ToxicFilterHandlerModule extends FilterModule {
  type: ModuleTypesEnum = ModuleTypesEnum.ToxicFilterHandlerModule;

  @Type(() => ToxicFilterHandlerModuleParams)
  params!: ToxicFilterHandlerModuleParams
}

class ToxicFilterHandlerModuleParams {
  mode!: ToxicFilterModeEnum
  percent!: number
}
