import NetworksModerateCommandHandlerModule from "./NetworksModerateCommandHandlerModule";
import ModerateCommandHandlerModuleBuilder
  from "@/includes/logic/Modules/models/modules/ModerateCommandHandlerModule/ModerateCommandHandlerModuleBuilder";
import { ModuleIcon } from "@/includes/logic/Modules/types/types";

export default class NetworksModerateCommandHandlerModuleBuilder extends ModerateCommandHandlerModuleBuilder {
  model!: NetworksModerateCommandHandlerModule;

  icon: ModuleIcon = {
    type: "Pack",
    pack: 'material-icons',
    name: 'travel_explore',
  }
}
