import { ModuleGroupEnum, ModuleIcon } from "@/includes/logic/Modules/types/types";
import ModuleBuilder from "@/includes/logic/Modules/Builders/ModuleBuilder";
import FirstInactiveKickHandlerModule
  from "@/includes/logic/Modules/models/modules/FirstInactiveKickHandlerModule/FirstInactiveKickHandlerModule";

export default class FirstInactiveKickHandlerModuleBuilder extends ModuleBuilder {
  model!: FirstInactiveKickHandlerModule;

  view: ModuleBuilder['view'] = () => import('../../../../../../components/Modules/modules/Activity/FirstInactiveKickHandlerModuleView.vue')
  group: ModuleGroupEnum = ModuleGroupEnum.ActivityBoost

  icon: ModuleIcon = {
    type: "Pack",
    pack: 'material-icons',
    name: 'delete',
  }
}

