import BaseModule from "@/includes/logic/Modules/models/BaseModule";
import { ModuleTypesEnum } from "@/includes/logic/Modules/types/types";
import { AdminOrAllPermissions } from "@/includes/types/Enums";

import { Type } from "class-transformer";
import MessageEditorWithMediaData
  from "piramis-base-components/src/components/NewMessageEditors/NewMessageEditorWithMedia/types";

export default class EditMediaHandlerModule extends BaseModule {
  type: ModuleTypesEnum = ModuleTypesEnum.EditMediaHandlerModule;

  @Type(() => EditMediaHandlerModuleParams)
  params!: EditMediaHandlerModuleParams;
}

class EditMediaHandlerModuleParams {
  remove_edited!: boolean

  remove_edited_timeout!:number
  remove_edited_msg!: number

  @Type(() => MessageEditorWithMediaData)
  edit_warning_message!: Array<MessageEditorWithMediaData>
}
