import BoardsApi from "@/includes/Api/Boards.api";
import { errorNotification } from "@/includes/NotificationService";
import { Board } from "@/views/posts-planner/posts.types";

export const getPostBoards = async (): Promise<Array<Board>> => {
  return BoardsApi.getPostBoards('tg')
      .then(({ data }) => {
        return data.boards
      })
      .catch((error) => {
        errorNotification(error)

        return []
  })
}
