var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{staticClass:"trigger-call-button-setup-view",attrs:{"slim":""}},[_c('text-input',{attrs:{"setup":{
      'func': _vm.modelSetter,
      'args': {
        model: _vm.model,
        'key': 'text',
        'prefix': 'button_trigger_call_',
        'validation': 'required',
      },
    }}}),_c('select-input',{attrs:{"setup":{
      'func': _vm.modelSetter,
      'args': {
        model: _vm.model,
        'key': 'code',
        'prefix': 'button_trigger_call_',
        'validation': 'required',
        'options': _vm.triggersSelectOptions,
        'clearable': false,
      },
    }}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }