import { ModuleConditionTypeEnum } from "@/includes/logic/Modules/types/types";
import ConfigCondition from "@/includes/logic/Modules/models/ConfigCondition";

export default class WorkTimeCondition extends ConfigCondition {
  type = ModuleConditionTypeEnum.WorkTime;

  to = ''

  from = ''
}
