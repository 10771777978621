import { ModuleGroupEnum, ModuleIcon } from "@/includes/logic/Modules/types/types";
import ModuleBuilder from "@/includes/logic/Modules/Builders/ModuleBuilder";

import RestrictCommentHandlerModule from "./RestrictCommentHandlerModule";

export default class RestrictCommentHandlerModuleBuilder extends ModuleBuilder {
  model!: RestrictCommentHandlerModule;

  view: ModuleBuilder['view'] = () => import('../../../../../../components/Modules/modules/RestrictCommentHandlerModuleView.vue')
  group: ModuleGroupEnum = ModuleGroupEnum.Extra

  icon: ModuleIcon = {
    type: "Pack",
    pack: 'material-icons',
    name: 'comments_disabled',
  }
}

