import BaseModule from "@/includes/logic/Modules/models/BaseModule";
import { ModuleTypesEnum } from "@/includes/logic/Modules/types/types";

import { Type } from "class-transformer";

export default class NewUserDeprecatedTriggersHandlerModule extends BaseModule {
  type: ModuleTypesEnum = ModuleTypesEnum.NewUserDeprecatedTriggersHandlerModule;

  @Type(() => NewUserDeprecatedTriggersHandlerModuleParams)
  params!: NewUserDeprecatedTriggersHandlerModuleParams;
}

class NewUserDeprecatedTriggersHandlerModuleParams {
  after_first_greeting_trigger!: string
  after_not_first_greeting_trigger!: string
  after_greeting_trigger!: string
}
