import BaseModule from "../BaseModule";
import CommonFilterSettings from "./CommonFilterSettings";
import { FilterTypes } from "@/views/chat/filters/FilterTypes";

import { Exclude, Type } from "class-transformer";
import { snakeCase } from "lodash";

export default abstract class FilterModule extends BaseModule {
  @Type(() => CommonFilterSettings)
  filter!: CommonFilterSettings

  journal_moderation_warn!: boolean
  journal_moderation_ban!: boolean

  filterType!: FilterTypes

  @Exclude()
  get snakeCaseFilterType() {
    return snakeCase(this.filterType)
  }
}
