import GreetingProtection from "./GreetingProtection";
import { GreetingProtectionTypeEnum, GreetingStringStrictModeEnum } from "../types";

export default class ExternalValidationGreetingProtection extends GreetingProtection {
  type: GreetingProtectionTypeEnum = GreetingProtectionTypeEnum.ExternalValidation;

  remove_not_confirm_users_timeout = 300
  ban_unverified_users_time = 60
  mode: GreetingStringStrictModeEnum = GreetingStringStrictModeEnum.Restrict
}
