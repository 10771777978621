import { ModuleTypesEnum } from "@/includes/logic/Modules/types/types";
import FilterModule from "@/includes/logic/Modules/models/filters/FilterModule";

import { Type } from "class-transformer";

export default class HieroglyphMessageFilterHandlerModule extends FilterModule {
  type: ModuleTypesEnum = ModuleTypesEnum.HieroglyphMessageFilterHandlerModule;

  @Type(() => HieroglyphMessageFilterHandlerModuleParams)
  params!: HieroglyphMessageFilterHandlerModuleParams;
}

class HieroglyphMessageFilterHandlerModuleParams {
  hieroglyph_message_max_percent!: number
}
