import { ModuleGroupEnum, ModuleIcon } from "@/includes/logic/Modules/types/types";
import ModuleBuilder from "@/includes/logic/Modules/Builders/ModuleBuilder";

import LeaveUsersHandlerModule from "./LeaveUsersHandlerModule";

export default class LeaveUsersHandlerModuleBuilder extends ModuleBuilder {
  model!: LeaveUsersHandlerModule;

  view: ModuleBuilder['view'] = () => import('../../../../../../../components/Modules/modules/Greeting/LeaveUsersHandlerModuleView.vue')
  group: ModuleGroupEnum = ModuleGroupEnum.Extra

  icon: ModuleIcon = {
    type: "Pack",
    pack: 'material-icons',
    name: 'exit_to_app',
  }

  aliases = [ 'прощание' ]
}

