import { ModuleTypesEnum } from "@/includes/logic/Modules/types/types";
import FilterModule from "@/includes/logic/Modules/models/filters/FilterModule";

import { BlackWhiteListItem } from "piramis-base-components/src/components/BlackWhiteList/types";

import { Type } from "class-transformer";

export default class DocumentFilterHandlerModule extends FilterModule {
  type: ModuleTypesEnum = ModuleTypesEnum.DocumentFilterHandlerModule;

  @Type(() => DocumentFilterHandlerModuleParams)
  params!: DocumentFilterHandlerModuleParams
}

class DocumentFilterHandlerModuleParams {
    files_extension_list!: BlackWhiteListItem<string>
    files_mime_type_list!: BlackWhiteListItem<string>
    files_min_file_size!: number
    files_max_file_size!: number
}
