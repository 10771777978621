import BaseModule from "@/includes/logic/Modules/models/BaseModule";
import { ModuleTypesEnum } from "@/includes/logic/Modules/types/types";
import { WeekDaysEnum } from "@/includes/logic/Modules/models/modules/CloseChatHandlerModule/types";

import { Type } from "class-transformer";
import MessageEditorWithMediaData
  from "piramis-base-components/src/components/NewMessageEditors/NewMessageEditorWithMedia/types";

export default class CloseChatHandlerModule extends BaseModule {
  type: ModuleTypesEnum = ModuleTypesEnum.CloseChatHandlerModule;

  @Type(() => CloseChatHandlerModuleParams)
  params!: CloseChatHandlerModuleParams;
}

class CloseChatHandlerModuleParams {
  close_chat_type!: "All" | "Media" | "AllNative" | "MediaNative"

  close_chat_interval!: {
    from: string | null
    to: string | null
  }

  @Type(() => MessageEditorWithMediaData)
  close_chat_message!: Array<MessageEditorWithMediaData>

  @Type(() => MessageEditorWithMediaData)
  open_chat_message!: Array<MessageEditorWithMediaData>

  @Type(() => MessageEditorWithMediaData)
  before_close_chat_message!: Array<MessageEditorWithMediaData>
  before_close_chat_message_interval!: number

  days_dont_open!: Array<WeekDaysEnum>
}
