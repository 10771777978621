import { ModuleGroupEnum, ModuleIcon } from "@/includes/logic/Modules/types/types";
import ModuleBuilder from "@/includes/logic/Modules/Builders/ModuleBuilder";

import FloodFilterHandlerModule from "./FloodFilterHandlerModule";

export default class FloodFilterHandlerModuleBuilder extends ModuleBuilder {
  model!: FloodFilterHandlerModule;

  group: ModuleGroupEnum = ModuleGroupEnum.MessagesModeration
  icon: ModuleIcon = {
    type: "Pack",
    pack: 'material-icons',
    name: "speaker_notes_off",
    color: "rgba(var(--a-warning), 1)"
  };
  view = () => import('@/components/Modules/modules/Filters/FloodFilterHandlerModuleView.vue')

  aliases = [ 'фильтры', 'удалять', 'удалить', ]

}
