import BaseModule from "@/includes/logic/Modules/models/BaseModule";
import { ModuleTypesEnum } from "@/includes/logic/Modules/types/types";
import AnchorData from "@/includes/logic/Anchors/AnchorData";

import { Type } from "class-transformer";

export default class AnchorsHandlerModule extends BaseModule {
  type: ModuleTypesEnum = ModuleTypesEnum.AnchorsHandlerModule;

  @Type(() => AnchorsHandlerModuleParams)
  params!: AnchorsHandlerModuleParams;
}

class AnchorsHandlerModuleParams {
  @Type(() => AnchorData)
  anchors!: Array<AnchorData>
}
