import { ModuleTypesEnum } from "@/includes/logic/Modules/types/types";
import FilterModule from "@/includes/logic/Modules/models/filters/FilterModule";
import { Type } from "class-transformer";

export default class AnimationFilterHandlerModule extends FilterModule {
  type: ModuleTypesEnum = ModuleTypesEnum.AnimationFilterHandlerModule;

  @Type(() => AnimationFilterHandlerModuleParams)
  params!: AnimationFilterHandlerModuleParams
}

class AnimationFilterHandlerModuleParams {
  gif_min_duaration!: number
  gif_max_duaration!: number
  gif_min_file_size!: number
  gif_max_file_size!: number
  gif_min_width!: number
  gif_max_width!: number
  gif_min_height!: number
  gif_max_height!: number
}
