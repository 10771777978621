import { GreetingProtectionTypeEnum } from "../types";
import BaseGreetingProtection from "./BaseGreetingProtection";

export default class InviteValidationGreetingProtection extends BaseGreetingProtection {
  type: GreetingProtectionTypeEnum = GreetingProtectionTypeEnum.InviteValidation

  count!: number

  button_text!: string
}
