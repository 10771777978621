import store from "@/store/store";
import { BaseChatInfo } from "@/includes/types/Chat/types";
import { errorNotification, successNotification } from "@/includes/NotificationService";
import RecurringInvoicesApi from "@/includes/Api/RecurringInvoices.api";

import {
  InvoiceInfo,
  InvoiceInfoWithTargets
} from "piramis-base-components/src/plugins/AdminTemplate/includes/types/Shared/InvoiceInfo.types";
import { SelectOptionData } from "piramis-base-components/src/components/Pi/types";

import Vue from "vue";

export function getRecurringInvoicesReq() {
  return RecurringInvoicesApi.getRecurringInvoices()
      .then(({ data }: { data: { invoices: Array<InvoiceInfoWithTargets> } }) => {
        const invoices = data.invoices

        if (invoices.length) {
          invoices.map(invoice => {
            invoice.targets = invoice.positions.reduce((acc: Array<SelectOptionData>, p) => {
              const chat = store.state.chatState.chatsInfo!.chats.find((c: BaseChatInfo) => c.chat_id === p.item_id)

              if (chat) {
                acc.push({
                  image: {
                    src: chat.group_photo,
                  },
                  label: `${ chat.group_title } (${ chat.chat_id })`,
                  value: chat.chat_id
                })
              }

              return acc
            }, [])

            return invoice
          })

          return invoices
        } else {
          return null
        }
      })
      .catch((error) => {
        errorNotification(error)

        return null
      })
}

export function deleteRecurringInvoice(invoice: InvoiceInfo, vm: Vue) {
  return new Promise<number | void>((resolve) => {
    const content = invoice.positions.length < 2
        ? vm.$t('remove_subscription_single_confirm_content').toString()
        : vm.$t('remove_subscription_multiple_confirm_content').toString()

    vm.$confirm({
      title: vm.$t('remove_subscription_confirm_title').toString(),
      content,
      onOk: () => {
        RecurringInvoicesApi.deleteRecurringInvoice(invoice.invoice_number)
            .then(() => {
              resolve(invoice.invoice_number)
              successNotification()
            })
            .catch(errorNotification)
      }
    })
  })

}

// export function findInvoiceWithTarget(target: number, invoices: Array<InvoiceInfoWithTargets>) {
//   const resInvoices: Array<InvoiceInfoWithTargets> = []
//
//   for (const invoice of invoices) {
//     if (invoice.positions && invoice.positions.length) {
//       if (invoice.positions.some(p => p.item_id && target === p.item_id)) {
//         resInvoices.push(invoice)
//       }
//     }
//   }
//
//   if (resInvoices.length) {
//     return resInvoices.sort((a, b) => b.invoice_number - a.invoice_number)[0]
//   }
//
//   return null
// }
