import BaseModule from "@/includes/logic/Modules/models/BaseModule";
import { ModuleTypesEnum } from "@/includes/logic/Modules/types/types";

import MessageEditorWithMediaData
  from "piramis-base-components/src/components/NewMessageEditors/NewMessageEditorWithMedia/types";

import { Type } from "class-transformer";

export default class NewUserTriggersHandlerModule extends BaseModule {
  type: ModuleTypesEnum = ModuleTypesEnum.NewUserTriggersHandlerModule;

  @Type(() => NewUserTriggersHandlerModuleParams)
  params!: NewUserTriggersHandlerModuleParams;
}

class NewUserTriggersHandlerModuleParams {
  after_captcha_success_trigger!: string
  after_first_enter_trigger!: string
  after_not_first_enter_trigger!: string
  after_enter_trigger!: string
  after_first_captcha_success_trigger!: string
  after_not_first_captcha_success_trigger!: string
  after_captcha_fail_trigger!: string

  @Type(() => MessageEditorWithMediaData)
  after_captcha_message!: Array<MessageEditorWithMediaData>

  @Type(() => MessageEditorWithMediaData)
  after_fail_captcha_message!: Array<MessageEditorWithMediaData>
}
