import { ModuleGroupEnum, ModuleIcon } from "@/includes/logic/Modules/types/types";
import ModuleBuilder from "@/includes/logic/Modules/Builders/ModuleBuilder";
import EnterBindedChannelHandlerModule from "./EnterBindedChannelHandlerModule";
import store from "@/store/store";
import { BRAND_TAG } from "@/includes/constants";

import { TagData } from "piramis-base-components/src/components/Tags/types";

export default class EnterBindedChannelHandlerModuleBuilder extends ModuleBuilder {
  model!: EnterBindedChannelHandlerModule;

  view: ModuleBuilder['view'] = () => import('../../../../../../components/Modules/modules/Facecontrol/EnterBindedChannelHandlerModuleView.vue')
  group: ModuleGroupEnum = ModuleGroupEnum.UsersModeration

  icon: ModuleIcon = {
    type: "Pack",
    pack: 'material-icons',
    name: 'do_not_disturb_on_total_silence',
  }

  tariffTags(): Array<TagData> | null {
    if (store.getters.isChatSet && (!store.getters.isChatLicenseExists || !store.getters.getHaveBrand || !store.getters.getHaveLicense)) {
      return BRAND_TAG
    }

    return null
  }

  isSaveable(): boolean {
    return this.model.params.binded_channel_id !== 0
  }
}

