import BaseModule from "@/includes/logic/Modules/models/BaseModule";
import { ModuleTypesEnum } from "@/includes/logic/Modules/types/types";

import MessageEditorWithMediaData
  from "piramis-base-components/src/components/NewMessageEditors/NewMessageEditorWithMedia/types";

import { Type } from "class-transformer";
import { PunishActions } from "@/includes/logic/Modules/models/PunishActions/types";

export default class MessageBindedChannelHandlerModule extends BaseModule {
  type: ModuleTypesEnum = ModuleTypesEnum.MessageBindedChannelHandlerModule;

  @Type(() => MessageBindedChannelHandlerModuleParams)
  params!: MessageBindedChannelHandlerModuleParams;
}

class MessageBindedChannelHandlerModuleParams {
  ban_action!: PunishActions
  warn_action!: PunishActions

  notice_before_ban_count!: number
  recheck_interval!: number
  binded_channel_id!: number

  journal!: boolean
}
