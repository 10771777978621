import { ModuleTypesEnum } from "@/includes/logic/Modules/types/types";
import FilterModule from "@/includes/logic/Modules/models/filters/FilterModule";

import { BlackWhiteListItem } from "piramis-base-components/src/components/BlackWhiteList/types";

import { Type } from "class-transformer";

export default class ChannelMessageFilterHandlerModule extends FilterModule {
  type: ModuleTypesEnum = ModuleTypesEnum.ChannelMessageFilterHandlerModule;

  @Type(() => ChannelMessageFilterHandlerModuleParams)
  params!: ChannelMessageFilterHandlerModuleParams
}

class ChannelMessageFilterHandlerModuleParams {
  channel_message_whitelist!: BlackWhiteListItem<string>
  min_subscribers!: number
}
