import { isEmpty, findIndex, cloneDeep }     from "lodash";
import Vue   from "vue";
import i18n  from "@/i18n/i18n";
import Store from "@/store/store";
import Api   from "./Api";

export default class VuTriggers {
	static OPTIONS = {
		method         : "post",
		url            : "",
		data           : {},
		withCredentials: true,
	};

  	static getTriggers() {
  	  return new Promise((resolve, reject) => {
  	    Api.getChatTriggers("tg", { chat_id: Store.state.chatState.chat.chat_id })
		 .then(res => {
		   resolve(res.data);
		 })
		 .catch(reject);
  	  });
  	}

	/**
	 * Если надо изменить триггер,
	 * нужно добавить id в тело сонфига
	 */
	static setTrigger(trigger) {
		return new Promise((resolve, reject) => {
			Api.setTrigger("tg", { trigger })
				 .then(res => {
					 resolve(res);
				 })
				 .catch(reject);
		});
	}

	static deleteTrigger(id) {
		return new Promise((resolve, reject) => {
			Api.deleteTrigger("tg", { id }).then(resolve).catch(reject);
		});
	}

	static updateTriggers(handle = false) {
		return new Promise((resolve, reject) => {
			const triggers = this.getTriggersFromStore();
			if(isEmpty(triggers) || handle === true) {
				Store.dispatch("updateTriggers")
						 .then(() => {
							 resolve();
						 })
						 .catch(err => {
							 reject(err);
						 });
			} else {
				resolve();
			}
		});
	}

	static getTriggersFromStore() {
		return Store.state.triggersState.triggers;
	}

  	static updateFrontendTriggers() {
  	  const { triggers } = cloneDeep(this.getTriggersFromStore());
  	  const chatTriggers = triggers.filter(trigger => trigger.chat_id === Store.state.chatState.chat.chat_id)
  	  Store.commit("updateFrontendTriggers", chatTriggers);
  	}

	static deleteFrontendTrigger(id) {
		return new Promise((resolve, reject) => {
		  Vue.prototype.$confirm({
		    title: i18n.t("trigger_confirm_remove").toString(),
		    content: i18n.t("trigger_confirm_remove_text").toString(),
		    okText: i18n.t("accept").toString(),
		    cancelText: i18n.t("reject").toString(),
		    onOk: () => {
		      this.deleteTrigger(id).then(res => {
			Store.commit("deleteFrontendTrigger", id);
			resolve(res);
		      }).catch(err => {
			reject(err);
		      });
		    },
		  })
		});
	}

	static setTriggerFrontEnd(data, isCopy = false) {
		let triggerTemplate;

		if(!isCopy) {
			triggerTemplate      = this.getTriggerTemplate();
			triggerTemplate.name = data;
		} else {
			triggerTemplate = data;
		}

		triggerTemplate = Object.assign(this.getTriggerTemplate(), triggerTemplate);

		Store.commit("setNewTrigger", triggerTemplate);
	}

	static getTriggerTemplate() {
		return {
			type                           : "trigger",
			name                           : "",
			chat_id                        : null,
			actionExecuteType              : "All",
			chance                         : 100,
			delay                          : 0,
			from                           : null,
			to                             : null,
			limit                          : 0,
			limitInterval                  : 0,
			globalLimit                    : 0,
			globalLimitInterval            : 0,
			warnLimit                      : 0,
			warnLimitInterval              : 0,
			conditionCheckInterval         : 0,
			processEditedMessages          : false,
			processEditedMessageOnes       : false,
			continueProcessAfterSuccessEdit: false,
			roundLimitInterval             : false,
			roundGlobalLimitInterval       : false,
			roundWarnLimitInterval         : false,
			transparent			: false,
			processAlbumsLikeFirstPhoto	: true,
			conditions                     : [],
			actions                        : [],
			conditionSuccessActions        : [],
			conditionFailActions           : [],
			limitActions                   : [],
			globalLimitActions             : [],
			warnActions                    : [],
		};
	}

	static createConditionItemTemplate() {
		return cloneDeep({
			type       : "MessageTextFullMatch",
			value      : [],
			filters    : [ "Trim", "CaseInsensitive" ],
			caller     : "Anybody",
			messageType: "Any",
			invert     : false,
		});
	}

	static createActionItemTemplate() {
		return {
			type       : "",
			value      : [],
			filters    : [],
			caller     : "",
			messageType: "",
		};
	}

	static getTrigger(id, isAll = false) {
		return new Promise((resolve, reject) => {
			this.getTriggers().then(res => {
				let triggerArr;
				if(isAll) {
					triggerArr = res.own.concat(res.chats);
				} else {
					triggerArr = res.own;
				}

				triggerArr.map(val => {
					if(val.id === id) {
						resolve(val);
					}
				});
			}).catch(err => {
				reject(err);
			});
		});
	}

  	static setActiveTrigger(trigger) {
  	  Store.commit("setActiveTrigger", trigger);
  	}

  	static getTriggerFormVuex(id) {
  	  const triggers     = Store.state.triggersState.frontendTriggers;
  	  const neededIndex  = findIndex(triggers, { id: id });
  	  return triggers[ neededIndex ];
  	}

	static copyTrigger(id) {
		const neededItem = cloneDeep(VuTriggers.getTriggerFormVuex(id));

		delete neededItem.id;
		delete neededItem.owner_id;

		this.setTriggerFrontEnd(neededItem, true);
	}

}
