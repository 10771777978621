




import { BaseChatInfo } from "@/includes/types/Chat/types";

import Vue from 'vue'
import { Component } from 'vue-property-decorator'

@Component
export default class BrandTagHelpView extends Vue {
  get firstChatWithAtLeastAdvancedId() {
    return this.$store.state.chatState.chatsInfo?.chats.find((chat: BaseChatInfo) => chat.license_type >= 1)?.chat_id
  }
}
