import { ModuleGroupEnum, ModuleIcon } from "@/includes/logic/Modules/types/types";
import ModuleBuilder from "@/includes/logic/Modules/Builders/ModuleBuilder";

import AnimationFilterHandlerModule from "./AnimationFilterHandlerModule";

export default class AnimationFilterHandlerModuleBuilder extends ModuleBuilder {
  model!: AnimationFilterHandlerModule;

  group: ModuleGroupEnum = ModuleGroupEnum.MessagesModeration

  icon: ModuleIcon = {
    type: "Pack",
    pack: 'material-icons',
    name: "gif",
    color: "rgba(var(--a-dark), 1)"
  };

  view =  () => import('@/components/Modules/modules/Filters/AnimationFilterHandlerModuleView.vue')

  aliases = [ 'гиф' ]
}
