import Api from "@/includes/logic/Api";
import {
  BaseChatInfo, SetChatCommonConfigReq,
  ChatConfig,
  NewChatConfig,
  RunChatActionType
} from "@/includes/types/Chat/types";

export default class ChatApi {
  static async getChatsInfo(): Promise<{ data: {chats: Array<BaseChatInfo>} }> {
    return Api.sendRequest('tg', 'getchatsinfo')
  }

  static async runChatAction({ chat_id, action }: {chat_id: BaseChatInfo['chat_id'], action: RunChatActionType }): Promise<{ data: { chat: ChatConfig } }> {
    return Api.sendRequest('tg', 'runchataction', { chat_id, action })
  }

  static async generateAccessToken(chat_id: BaseChatInfo['chat_id']): Promise<{ data: { access_token: string } }> {
    return Api.generateAccessToken('tg', { chat_id })
  }

  static async migrateChatToModules (chat_id: number): Promise<{data: { chat: NewChatConfig }}> {
    return Api.sendRequest('tg', 'migratechattomodules', { chat_id })
  }

  static async setChatCommonConfig (chat_id: number, config: SetChatCommonConfigReq): Promise<{data: { chat: NewChatConfig }}> {
    return Api.sendRequest('tg', 'setchatcommonconfig', { chat_id, ...config })
  }
}

