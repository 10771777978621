import {
  PiWizardActionTypeEnum,
  PiWizardConfig,
  PiWizardFieldTypeEnum,
  PiWizardNavigationButtonEnum
} from "@/components/Wizard/types";
import store from "@/store/store";
import { GreetingProtectionTypeEnum } from "@/includes/logic/Modules/models/modules/Greeting/NewUsersHandlerModule/types";
import { ModuleTypesEnum } from "@/includes/logic/Modules/types/types";
import NewUsersHandlerModule
  from "@/includes/logic/Modules/models/modules/Greeting/NewUsersHandlerModule/NewUsersHandlerModule";

import { timezoneList } from "piramis-base-components/src/logic/timizone-list";

import { merge } from "lodash";

export const chatWizardConfig: PiWizardConfig = {
  pages: {
    '1': {
      page: {
        layout: () => import('./components/PiBaseLayout.vue'),
        title: {
          key: 'wizard_chat_screen_1_title',
          values: [ store.state.chatState.chat?.group_title ]
        },
        headerDescription: {
          key: 'wizard_chat_screen_1_header_description'
        },
        fields: [
          {
            type: PiWizardFieldTypeEnum.ControlButton,
            title: {
              key: 'wizard_chat_screen_1_control_action_goto_2_title'
            },
            antType: "primary",
            gotoPageKey: '2'
          },
          {
            type: PiWizardFieldTypeEnum.ControlButton,
            title: {
              key: 'wizard_chat_screen_1_control_action_goto_2_1_title'
            },
            antType: "primary",
            gotoPageKey: '2.1'
          },
        ],
      }
    },
    '2.1': {
      page: {
        layout: () => import('./components/PiBaseLayout.vue'),
        title: {
          key: 'wizard_chat_screen_2_1_title'
        },
        headerDescription: {
          key: 'wizard_chat_screen_2_1_header_description',
          values: [ store.state.chatState.chat?.group_title ]
        },
        fields: [],
        navigation: [
          {
            type: PiWizardNavigationButtonEnum.BackButton,
            gotoPageKey: '1'
          },
          {
            type: PiWizardNavigationButtonEnum.CompleteButton,
            classNames: [ 'btn-success' ],
            title: {
              key: 'wizard_chat_screen_2_1_navigation_button_complete'
            },
            onComplete: (model, closeWizard) => {
              store.dispatch('setStartModules', { modules: {} })
                  .then(res => {
                    if (res) {
                      closeWizard()
                    }
                  })
            }
          }
        ]
      }
    },
    '2': {
      page: {
        title: {
          key: 'wizard_chat_screen_2_title'
        },
        headerDescription: {
          key: 'wizard_chat_screen_2_header_description'
        },
        footerDescription: {
          key: 'wizard_chat_screen_2_footer_description'
        },
        layout: () => import('./components/PiBaseLayout.vue'),
        fields: [
          {
            type: PiWizardFieldTypeEnum.ManualCheckbox,
            initValue: model => model?.modules?.[ModuleTypesEnum.NewUsersHandlerModule]?.params?.protection?.type === GreetingProtectionTypeEnum.Standart,
            checkboxStateActions: {
              true: 'action1',
              false: 'action2',
            },
            localFieldPath: {
              key: ModuleTypesEnum.NewUsersHandlerModule,
              prefix: 'chat_wizard_screen_2_checkbox_',
            }
          }
        ],
        navigation: [
          {
            type: PiWizardNavigationButtonEnum.BackButton,
            gotoPageKey: '1'
          },
          {
            type: PiWizardNavigationButtonEnum.NextButton,
            gotoPageKey: '3'
          }
        ],
      },
      actions: {
        'action1': {
          type: PiWizardActionTypeEnum.Set,
          items: [
            {
              path: `modules.${ ModuleTypesEnum.NewUsersHandlerModule }`,
              value: {
                params: {
                  protection: {
                    type: GreetingProtectionTypeEnum.Standart,
                  }
                }
              } as Partial<NewUsersHandlerModule>
            }
          ]
        },
        'action2': {
          type: PiWizardActionTypeEnum.Set,
          items: [
            {
              path: `modules.${ ModuleTypesEnum.NewUsersHandlerModule }`,
              value: {
                params: {
                  protection: {
                    type: GreetingProtectionTypeEnum.None,
                  }
                }
              } as Partial<NewUsersHandlerModule>
            }
          ]
        },
      }
    },
    '3': {
      page: {
        title: {
          key: 'wizard_chat_screen_3_title'
        },
        footerDescription: {
          key: 'wizard_chat_screen_3_footer_description'
        },
        layout: () => import('./components/PiBaseLayout.vue'),
        fields: [
          {
            type: PiWizardFieldTypeEnum.ManualCheckbox,
            initValue: model => !!model?.modules?.[ModuleTypesEnum.CensureFilterHandlerModule],
            checkboxStateActions: {
              true: 'CensureFilterHandlerModuleTrue',
              false: 'CensureFilterHandlerModuleFalse',
            },
            localFieldPath: {
              key: ModuleTypesEnum.CensureFilterHandlerModule,
              prefix: 'chat_wizard_screen_3_checkbox_'
            }
          },
          {
            type: PiWizardFieldTypeEnum.ManualCheckbox,
            initValue: model => !!model?.modules?.[ModuleTypesEnum.WebLinksFilterHandlerModule],
            checkboxStateActions: {
              true: 'WebLinksFilterHandlerModuleTrue',
              false: 'WebLinksFilterHandlerModuleFalse',
            },
            localFieldPath: {
              key: ModuleTypesEnum.WebLinksFilterHandlerModule,
              prefix: 'chat_wizard_screen_3_checkbox_'
            }
          },
          {
            type: PiWizardFieldTypeEnum.ManualCheckbox,
            initValue: model => !!model?.modules?.[ModuleTypesEnum.SelfLinksFilterHandlerModule],
            checkboxStateActions: {
              true: 'SelfLinksFilterHandlerModuleTrue',
              false: 'SelfLinksFilterHandlerModuleFalse',
            },
            localFieldPath: {
              key: ModuleTypesEnum.SelfLinksFilterHandlerModule,
              prefix: 'chat_wizard_screen_3_checkbox_'
            }
          },
          {
            type: PiWizardFieldTypeEnum.ManualCheckbox,
            initValue: model => !!model?.modules?.[ModuleTypesEnum.BotReplyFilterHandlerModule] && !!model?.modules?.[ModuleTypesEnum.ChatReplyFilterHandlerModule] && !!model?.modules?.[ModuleTypesEnum.ChannelReplyFilterHandlerModule],
            checkboxStateActions: {
              true: 'ReplyTrue',
              false: 'ReplyFalse',
            },
            localFieldPath: {
              key: 'Reply',
              prefix: 'chat_wizard_screen_3_checkbox_'
            }
          },
          {
            type: PiWizardFieldTypeEnum.ManualCheckbox,
            initValue: model => !!model?.modules?.[ModuleTypesEnum.VideoNoteFilterHandlerModule] && !!model?.modules?.[ModuleTypesEnum.VoiceFilterHandlerModule],
            checkboxStateActions: {
              true: 'VoiceMediaTrue',
              false: 'VoiceMediaFalse',
            },
            localFieldPath: {
              key: 'VoiceMedia',
              prefix: 'chat_wizard_screen_3_checkbox_'
            }
          }
        ],
        navigation: [
          {
            type: PiWizardNavigationButtonEnum.BackButton,
            gotoPageKey: '2'
          },
          {
            type: PiWizardNavigationButtonEnum.NextButton,
            gotoPageKey: '4'
          }
        ],
      },
      actions: {
        CensureFilterHandlerModuleTrue: {
          type: PiWizardActionTypeEnum.Set,
          items: [
            {
              path: `modules.${ ModuleTypesEnum.CensureFilterHandlerModule }`,
              value: {}
            }
          ]
        },
        CensureFilterHandlerModuleFalse: {
          type: PiWizardActionTypeEnum.Unset,
          items: [
            {
              path: `modules.${ ModuleTypesEnum.CensureFilterHandlerModule }}`
            }
          ]
        },
        WebLinksFilterHandlerModuleTrue: {
          type: PiWizardActionTypeEnum.Set,
          items: [
            {
              path: `modules.${ ModuleTypesEnum.WebLinksFilterHandlerModule }`,
              value: {}
            }
          ]
        },
        WebLinksFilterHandlerModuleFalse: {
          type: PiWizardActionTypeEnum.Unset,
          items: [
            {
              path: `modules.${ ModuleTypesEnum.WebLinksFilterHandlerModule }`
            }
          ]
        },
        SelfLinksFilterHandlerModuleTrue: {
          type: PiWizardActionTypeEnum.Set,
          items: [
            {
              path: `modules.${ ModuleTypesEnum.SelfLinksFilterHandlerModule }`,
              value: {}
            }
          ]
        },
        SelfLinksFilterHandlerModuleFalse: {
          type: PiWizardActionTypeEnum.Unset,
          items: [
            {
              path: `modules.${ ModuleTypesEnum.SelfLinksFilterHandlerModule }`
            }
          ]
        },
        ReplyTrue: {
          type: PiWizardActionTypeEnum.Set,
          items: [
            {
              path: `modules.${ ModuleTypesEnum.ChannelReplyFilterHandlerModule }`,
              value: {}
            },
            {
              path: `modules.${ ModuleTypesEnum.ChatReplyFilterHandlerModule }`,
              value: {}
            },
            {
              path: `modules.${ ModuleTypesEnum.BotReplyFilterHandlerModule }`,
              value: {}
            }
          ]
        },
        ReplyFalse: {
          type: PiWizardActionTypeEnum.Unset,
          items: [
            {
              path: `modules.${ ModuleTypesEnum.ChannelReplyFilterHandlerModule }`,
            },
            {
              path: `modules.${ ModuleTypesEnum.ChatReplyFilterHandlerModule }`,
            },
            {
              path: `modules.${ ModuleTypesEnum.BotReplyFilterHandlerModule }`,
            }
          ]
        },
        VoiceMediaTrue: {
          type: PiWizardActionTypeEnum.Set,
          items: [
            {
              path: `modules.${ ModuleTypesEnum.VideoNoteFilterHandlerModule }`,
              value: {}
            },
            {
              path: `modules.${ ModuleTypesEnum.VoiceFilterHandlerModule }`,
              value: {}
            }
          ]
        },
        VoiceMediaFalse: {
          type: PiWizardActionTypeEnum.Unset,
          items: [
            {
              path: `modules.${ ModuleTypesEnum.VideoNoteFilterHandlerModule }`,
            },
            {
              path: `modules.${ ModuleTypesEnum.VoiceFilterHandlerModule }`,
            }
          ]
        }
      },
    },
    '4': {
      page: {
        title: {
          key: 'wizard_chat_screen_4_title'
        },
        headerDescription: {
          key: 'wizard_chat_screen_4_header_description'
        },
        footerDescription: {
          key: 'wizard_chat_screen_4_footer_description'
        },
        condition: "condition1",
        layout: () => import('./components/PiBaseLayout.vue'),
        fields: [
          {
            type: PiWizardFieldTypeEnum.ManualCheckbox,
            initValue: model => !!model?.modules?.[ModuleTypesEnum.ReputationMessageHandlerModule],
            checkboxStateActions: {
              true: 'ReputationMessageHandlerModuleTrue',
              false: 'ReputationMessageHandlerModuleFalse',
            },
            localFieldPath: {
              key: ModuleTypesEnum.ReputationMessageHandlerModule,
              prefix: 'chat_wizard_screen_4_checkbox_'
            }
          },
          {
            type: PiWizardFieldTypeEnum.ManualCheckbox,
            initValue: model => !!model?.modules?.[ModuleTypesEnum.ReportCommandHandlerModule],
            checkboxStateActions: {
              true: 'ReportCommandHandlerModuleTrue',
              false: 'ReportCommandHandlerModuleFalse',
            },
            localFieldPath: {
              key: ModuleTypesEnum.ReportCommandHandlerModule,
              prefix: 'chat_wizard_screen_4_checkbox_'
            }
          },
          {
            type: PiWizardFieldTypeEnum.ManualCheckbox,
            initValue: model => !!model?.modules?.[ModuleTypesEnum.WarnCommandHandlerModule],
            checkboxStateActions: {
              true: 'WarnCommandHandlerModuleTrue',
              false: 'WarnCommandHandlerModuleFalse',
            },
            localFieldPath: {
              key: ModuleTypesEnum.WarnCommandHandlerModule,
              prefix: 'chat_wizard_screen_4_checkbox_'
            }
          },
        ],
        navigation: [
          {
            type: PiWizardNavigationButtonEnum.BackButton,
            gotoPageKey: '3',
          },
          {
            type: PiWizardNavigationButtonEnum.NextButton,
            gotoPageKey: '5'
          }
        ],
      },
      actions: {
        ReputationMessageHandlerModuleTrue: {
          type: PiWizardActionTypeEnum.Set,
          items: [
            {
              path: `modules.${ ModuleTypesEnum.ReputationMessageHandlerModule }`,
              value: {}
            }
          ]
        },
        ReputationMessageHandlerModuleFalse: {
          type: PiWizardActionTypeEnum.Unset,
          items: [
            {
              path: `modules.${ ModuleTypesEnum.ReputationMessageHandlerModule }`
            }
          ]
        },
        ReportCommandHandlerModuleTrue: {
          type: PiWizardActionTypeEnum.Set,
          items: [
            {
              path: `modules.${ ModuleTypesEnum.ReportCommandHandlerModule }`,
              value: {}
            }
          ]
        },
        ReportCommandHandlerModuleFalse: {
          type: PiWizardActionTypeEnum.Unset,
          items: [
            {
              path: `modules.${ ModuleTypesEnum.ReportCommandHandlerModule }`,
            }
          ]
        },
        WarnCommandHandlerModuleTrue: {
          type: PiWizardActionTypeEnum.Set,
          items: [
            {
              path: `modules.${ ModuleTypesEnum.WarnCommandHandlerModule }`,
              value: {}
            }
          ]
        },
        WarnCommandHandlerModuleFalse: {
          type: PiWizardActionTypeEnum.Unset,
          items: [
            {
              path: `modules.${ ModuleTypesEnum.WarnCommandHandlerModule }`,
            }
          ]
        }
      },
    },
    '5': {
      page: {
        layout: () => import('./components/PiBaseLayout.vue'),
        title: {
          key: 'wizard_chat_screen_5_title'
        },
        headerDescription: {
          key: 'wizard_chat_screen_5_header_description'
        },
        footerDescription: {
          key: 'wizard_chat_screen_5_footer_description'
        },
        fields: [
          {
            type: PiWizardFieldTypeEnum.ManualCheckbox,
            initValue: model => !!model?.journal_instruction,
            checkboxStateActions: {
              true: 'InstructionTrue',
              false: 'InstructionFalse',
            },
            localFieldPath: {
              key: 'journal_instruction',
              prefix: 'chat_wizard_screen_5_checkbox_'
            }
          },
        ],
        navigation: [
          {
            type: PiWizardNavigationButtonEnum.BackButton,
            gotoPageKey: '4'
          },
          {
            type: PiWizardNavigationButtonEnum.NextButton,
            gotoPageKey: '6'
          }
        ]
      },
      actions: {
        InstructionTrue: {
          type: PiWizardActionTypeEnum.Set,
          items: [
            {
              path: 'journal_instruction',
              value: true
            }
          ]
        },
        InstructionFalse: {
          type: PiWizardActionTypeEnum.Unset,
          items: [
            {
              path: 'journal_instruction'
            }
          ]
        }
      },
    },
    '6': {
      page: {
        layout: () => import('./components/PiBaseLayout.vue'),
        title: {
          key: 'wizard_chat_screen_6_title'
        },
        headerDescription: {
          key: 'wizard_chat_screen_6_header_description'
        },
        fields: [
          {
            type: PiWizardFieldTypeEnum.ManualSelect,
            options: timezoneList,
            clearable: false,
            multiple: false,
            initValue: model => model?.timezone ?? 'UTC',
            localFieldPath: {
              key: 'timezone',
              prefix: 'chat_wizard_screen_6_timezone_'
            }
          },
        ],
        navigation: [
          {
            type: PiWizardNavigationButtonEnum.BackButton,
            gotoPageKey: '5'
          },
          {
            type: PiWizardNavigationButtonEnum.NextButton,
            gotoPageKey: '7'
          }
        ]
      },
    },
    '7': {
      page: {
        layout: () => import('./components/PiBaseLayout.vue'),
        title: {
          key: 'wizard_chat_screen_7_title',
          values: [ store.state.chatState.chat?.group_title ]
        },
        headerDescription: {
          key: 'wizard_chat_screen_7_header_description',
          values: [ store.state.chatState.chat?.group_title ]
        },
        navigation: [
          {
            type: PiWizardNavigationButtonEnum.BackButton,
            gotoPageKey: '6'
          },
          {
            type: PiWizardNavigationButtonEnum.CompleteButton,
            classNames: [ 'btn-success' ],
            title: {
              key: 'wizard_chat_screen_7_navigation_button_complete'
            },
            onComplete: (model, closeWizard) => {
              if (!model?.modules) {
                model = merge(model, { modules: {} })
              }

              store.dispatch('setStartModules', model)
                  .then(res => {
                    if (res) {
                      closeWizard()
                    }
                  })
            }
          }
        ],
      }
    }
  }
}
