import { PunishActions } from "@/includes/logic/Modules/models/PunishActions/types";
import { CommentsModeEnum } from "./types";

export default class CommonFilterSettings {
  enabled_common_filter!: boolean
  comments_mode!: CommentsModeEnum

  warning_punish_action!: PunishActions
  ban_punish_action!: PunishActions

  notice_before_ban_count!: number

  allowed_count!: number
  allowed_interval!: number
  allowed_interval_round!: number

  ignore_caption!: boolean
  handle_user_bio!: boolean

  take_away_reputation!: number
  take_away_xp!: number

  first_message_punish_action!: PunishActions

  new_limit_timeout!: number
  new_limit_msg_count!: number
  new_limit_punish_action!: PunishActions

  // removed_edited -- old
  process_edit_separately!: boolean
  remove_edited_timeout!: number
  remove_edited_msg!: number
  remove_edited_punish_action!: PunishActions
}
