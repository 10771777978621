const TokenId = {
  getId(tokenKey: string, splitSymbol = '.') {
    const split = tokenKey.split(splitSymbol)

    if (split && split.length && split[0]) {
      return split[0]
    }

    return ''
  },
  getToken(tokenKey: string, splitSymbol = '.') {
    const split = tokenKey.split(splitSymbol)

    if (split && split.length && split[1]) {
      return split[1]
    }

    return ''
  },
  create(id: string | number, token: string | number, divider = '.') {
    return `${ id }${ divider }${ token }`
  }
}

export default TokenId
