export default class ScriptLoader {
  private loadCount: number
  private readonly totalRequired: number

  constructor(
      private scripts: Array<string>,
      private callback: Function
  ) {
    this.loadCount = 0
    this.totalRequired = scripts.length
  }

  private loaded() {
    this.loadCount++;
    if (this.loadCount == this.totalRequired) this.callback();
  }

  require() {
    for (let i = 0; i < this.scripts.length; i++) {
      this.writeScript(this.scripts[i]);
    }
  }

  writeScript(src: string) {
    const s = document.createElement('script');
    s.type = "text/javascript";
    s.async = true;
    s.src = src;
    s.addEventListener('load', (): void => this.loaded(), false);
    const head = document.getElementsByTagName('head')[0];
    head.appendChild(s);
  }
}
