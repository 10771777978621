import BaseModule from "@/includes/logic/Modules/models/BaseModule";
import { ModuleTypesEnum } from "@/includes/logic/Modules/types/types";

import MessageEditorWithMediaData
  from "piramis-base-components/src/components/NewMessageEditors/NewMessageEditorWithMedia/types";

import { Type } from "class-transformer";

export default class FirstInactiveKickHandlerModule extends BaseModule {
  type: ModuleTypesEnum = ModuleTypesEnum.FirstInactiveKickHandlerModule;

  @Type(() => FirstInactiveKickHandlerModuleParams)
  params!: FirstInactiveKickHandlerModuleParams;
}

class FirstInactiveKickHandlerModuleParams {
    first_inactive_kick_interval!: number

    @Type(() => MessageEditorWithMediaData)
    first_inactive_kick_warn1!: Array<MessageEditorWithMediaData>

    @Type(() => MessageEditorWithMediaData)
    first_inactive_kick_warn2!: Array<MessageEditorWithMediaData>
}
