import { ModuleGroupEnum, ModuleIcon } from "@/includes/logic/Modules/types/types";
import ModuleBuilder from "@/includes/logic/Modules/Builders/ModuleBuilder";
import EntertainmentCallHandlerModule from "./EntertainmentCallHandlerModule";
import store from "@/store/store";
import { BRAND_TAG } from "@/includes/constants";

import { TagData } from "piramis-base-components/src/components/Tags/types";

export default class EntertainmentCallHandlerModuleBuilder extends ModuleBuilder {
  model!: EntertainmentCallHandlerModule;

  view: ModuleBuilder['view'] = () => import('../../../../../../components/Modules/modules/Activity/EntertainmentCallHandlerModuleView.vue')
  group: ModuleGroupEnum = ModuleGroupEnum.ActivityBoost

  tariffTags(): Array<TagData> | null {
    if (store.getters.isChatSet && (!store.getters.isChatLicenseExists || !store.getters.getHaveBrand || !store.getters.getHaveLicense)) {
      return BRAND_TAG
    } else {
      return null
    }
  }

  icon: ModuleIcon = {
    type: "Pack",
    pack: "material-icons",
    name: 'record_voice_over',
  }
}

