import { ModuleGroupEnum, ModuleIcon } from "@/includes/logic/Modules/types/types";
import ModuleBuilder from "@/includes/logic/Modules/Builders/ModuleBuilder";
import NewUsersAttackHandlerModule from "./NewUsersAttackHandlerModule";

import { TagData } from "piramis-base-components/src/components/Tags/types";

export default class NewUsersAttackHandlerModuleBuilder extends ModuleBuilder {
  model!: NewUsersAttackHandlerModule;

  view: ModuleBuilder['view'] = () => import('../../../../../../components/Modules/modules/ModerationExtra/NewUsersAttackHandlerModuleView.vue')
  group: ModuleGroupEnum = ModuleGroupEnum.UsersModeration

  tariffTags(): Array<TagData> | null {
    return this.checkLimitOption('auto_attack_mode')
  }

  icon: ModuleIcon = {
    type: "Pack",
    pack: 'material-icons',
    name: 'local_fire_department',
  }
}

