import chatAction     from "./chats/actions.ts";
import triggersAction from "./triggers/actions";
import postsPlannerActions from "./postsPlanner/actions"
import networksActions from "./networks/actions"

const actions = {
	// chat
	...chatAction,
	// triggers
	...triggersAction,
  	// posts planner
	...postsPlannerActions,
	...networksActions,
};

export default actions;
