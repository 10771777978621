import { ModuleGroupEnum, ModuleIcon } from "@/includes/logic/Modules/types/types";
import ModuleBuilder from "@/includes/logic/Modules/Builders/ModuleBuilder";

import ContactFilterHandlerModule from "./ContactFilterHandlerModule";

export default class ContactFilterHandlerModuleBuilder extends ModuleBuilder {
  model!: ContactFilterHandlerModule;

  group: ModuleGroupEnum = ModuleGroupEnum.MessagesModeration
  icon: ModuleIcon = {
    type: "Pack",
    pack: 'material-icons',
    name: "contacts",
    color: "rgba(var(--a-dark), 1)"
  };
  view =  () => import('@/components/Modules/modules/Filters/ContactFilterHandlerModuleView.vue')

  aliases = [ 'номер', 'фильтры', 'удалять', 'удалить', ]

}
