import { ModuleTypesEnum } from "@/includes/logic/Modules/types/types";
import FilterModule from "@/includes/logic/Modules/models/filters/FilterModule";
import { Type } from "class-transformer";

export default class VideoFilterHandlerModule extends FilterModule {
  type: ModuleTypesEnum = ModuleTypesEnum.VideoFilterHandlerModule;

  @Type(() => VideoFilterHandlerModuleParams)
  params!: VideoFilterHandlerModuleParams
}

class VideoFilterHandlerModuleParams {
    video_min_duaration!: number
    video_max_duaration!: number
    video_min_file_size!: number
    video_max_file_size!: number
    video_min_width!: number
    video_max_width!: number
    video_min_height!: number
    video_max_height!: number
}
