export enum GreetingHandleTypeEnum {
  Default = 'Default',
  Disabled = 'Disabled',
  Enabled = 'Enabled',
}

export enum GreetingProtectionTypeEnum {
  None = 'None',
  Standart = 'Standart',
  InviteValidation = 'InviteValidation',
  FormValidation = 'FormValidation',
  VariableCaptcha = 'VariableCaptcha',
  MathCaptcha = 'MathCaptcha',
  BindedChannel = 'BindedChannel',
  ExternalValidation = 'ExternalValidation',
}

export enum GreetingStringStrictModeEnum {
  Restrict = 'Restrict',
  AllowWriteMessages = 'AllowWriteMessages'
}
