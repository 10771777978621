import { ModuleGroupEnum, ModuleIcon } from "@/includes/logic/Modules/types/types";
import ModuleBuilder from "@/includes/logic/Modules/Builders/ModuleBuilder";

import FirstCommentHandlerModule from "./FirstCommentHandlerModule";

export default class FirstCommentHandlerModuleBuilder extends ModuleBuilder {
  model!: FirstCommentHandlerModule;

  view: ModuleBuilder['view'] = () => import('../../../../../../components/Modules/modules/FirstCommentHandlerModuleView.vue')
  group: ModuleGroupEnum = ModuleGroupEnum.Extra

  icon: ModuleIcon = {
    type: "Pack",
    pack: 'material-icons',
    name: 'add_comment',
  }
}

