import ModulesApi from "@/includes/Api/Modules.api";
import { errorNotification } from "@/includes/NotificationService";
import BaseModule from "@/includes/logic/Modules/models/BaseModule";
import { ModuleTypesEnum } from "@/includes/logic/Modules/types/types";

export function setModuleConfig (chatId: number, module: BaseModule) {
  return ModulesApi.setModuleConfig(chatId, module)
      .then(({ data }) => data)
      .catch(errorNotification)
}

export function getDefaultModuleConfig(chatId: number, type: ModuleTypesEnum) {
  return ModulesApi.getDefaultModuleConfig(chatId, type)
      .then(({ data }) => data.module)
      .catch(errorNotification)
}
