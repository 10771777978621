import { ModuleGroupEnum, ModuleIcon } from "@/includes/logic/Modules/types/types";
import ModuleBuilder from "@/includes/logic/Modules/Builders/ModuleBuilder";

import RtlMessageFilterHandlerModule from "./RtlMessageFilterHandlerModule";

export default class RtlMessageFilterHandlerModuleBuilder extends ModuleBuilder {
  model!: RtlMessageFilterHandlerModule;

  group: ModuleGroupEnum = ModuleGroupEnum.MessagesModeration
  icon: ModuleIcon = {
    type: "Pack",
    pack: 'material-icons',
    name: "format_textdirection_r_to_l",
    color: "rgba(var(--a-danger), 1)"
  };
  view =  () => import('@/components/Modules/modules/Filters/RtlMessageFilterHandlerModuleView.vue')

  aliases = [ 'фильтры', 'удалять', 'удалить', ]

}
