import Api from "@/includes/logic/Api";
import {
  CreateNetworkReq,
  BaseNetworkData,
  NetworkStat,
  SetNetworkConfigReq, NetworkConfig
} from "@/includes/types/networks";
import { BaseChatInfo, ChatConfigSettings } from "@/includes/types/Chat/types";
import { RawTriggerData } from "@/components/TriggerSetup/logic/types/types";
import { NetworkConfigImport } from "../types/ImportConfig.types";

import { AxiosPromise } from "axios";

export default class NetworksApi {
  static async createNetwork(type: 'tg', body: CreateNetworkReq): Promise<{ data: BaseNetworkData }> {
    return await Api.sendRequest(type, 'createnetwork', body)
  }

  static async deleteNetwork(type: 'tg', id: BaseNetworkData['id']): Promise<{data: { result: string }}> {
    return await Api.sendRequest(type, 'deleteNetwork', { id })
  }

  static async getMyNetworks(type: 'tg'): Promise<{ data: { networks: Array<BaseNetworkData> } }> {
    return await Api.sendRequest(type, 'getmynetworks')
  }

  static async getNetworkStat(type: 'tg', id: BaseNetworkData['id']): AxiosPromise<NetworkStat> {
    return await Api.sendRequest(type, 'getnetworkstat', { id })
  }

  static async removeGroupFromNetwork(type: 'tg', chat_id: BaseChatInfo['chat_id'], network_id: BaseNetworkData['id']): AxiosPromise<ChatConfigSettings> {
    return await Api.sendRequest(type, 'removegroupfromnetwork', { chat_id, network_id })
  }

  static async getNetworkTriggers(type: 'tg', id: BaseNetworkData['id']): AxiosPromise<{ triggers: Array<RawTriggerData> }> {
    return await Api.sendRequest(type, 'getnetworktriggers', { id })
  }

  static async getNetworkTrigger(type: 'tg', network_id: BaseNetworkData['id'], triggerId: string): AxiosPromise<RawTriggerData> {
    return await Api.sendRequest(type, 'getnetworktrigger', { network_id, id: triggerId })
  }

  static async setNetworkTrigger(type: 'tg', network_id: BaseNetworkData['id'], triggerId: string, trigger: RawTriggerData): AxiosPromise<Required<RawTriggerData & { result: string }>> {
    return await Api.sendRequest(type, 'setnetworktrigger', { network_id, id: triggerId, trigger })
  }

  static async deleteNetworkTrigger(type: 'tg', network_id: BaseNetworkData['id'], id: string): AxiosPromise<BaseNetworkData> {
    return await Api.sendRequest(type, 'deletenetworktrigger', { network_id, id })
  }

  static async getNetworkConfig(type: 'tg', network_id: BaseNetworkData['id'] ): AxiosPromise<NetworkConfig> {
    return await Api.sendRequest(type, 'getnetworkconfig', { network_id })
  }

  static async setNetworkConfig(type: 'tg', body: SetNetworkConfigReq ): AxiosPromise<NetworkConfig> {
    return await Api.sendRequest(type, 'setnetworkconfig', body)
  }

  static async setNetworkModuleConfig(type: 'tg', { network_id, module }: { network_id: BaseNetworkData['id'], module: Record<string, any>}): AxiosPromise<BaseNetworkData> {
    return await Api.sendRequest(type, 'setnetworkmoduleconfig',  { network_id, module })
  }

  static async applyNetworkConfigToGroup(type: 'tg', { network, chat_id, import_types }: NetworkConfigImport): AxiosPromise<{ chat: ChatConfigSettings }> {
    return await Api.sendRequest(type, 'applynetworkconfigtogroup',  { network, chat_id, import_types })
  }
}
