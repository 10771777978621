import { ModuleTypesEnum } from "@/includes/logic/Modules/types/types";
import FilterModule from "@/includes/logic/Modules/models/filters/FilterModule";
import { Type } from "class-transformer";

export default class PhotoFilterHandlerModule extends FilterModule {
  type: ModuleTypesEnum = ModuleTypesEnum.PhotoFilterHandlerModule;

  @Type(() => PhotoFilterHandlerModuleParams)
  params!: PhotoFilterHandlerModuleParams
}

class PhotoFilterHandlerModuleParams {
    images_min_file_size!: number
    images_max_file_size!: number
    images_min_width!: number
    images_max_width!: number
    images_min_height!: number
    images_max_height!: number
}
