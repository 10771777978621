import BaseModule from "@/includes/logic/Modules/models/BaseModule";
import { ModuleTypesEnum } from "@/includes/logic/Modules/types/types";
import { CommandLimit } from "@/includes/logic/CommandLimits/types";

import { Type } from "class-transformer";

export default class CommandLimitHandlerModule extends BaseModule {
  type: ModuleTypesEnum = ModuleTypesEnum.CommandLimitHandlerModule;

  @Type(() => CommandLimitHandlerModuleParams)
  params!: CommandLimitHandlerModuleParams;
}

class CommandLimitHandlerModuleParams {
  check_native_limits!: boolean
  demote_admins!: boolean
  command_limits!: Array<CommandLimit>
}
