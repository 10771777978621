import { FilterTypes } from "@/views/chat/filters/FilterTypes";
import CommonFilterSettings from "@/includes/logic/Modules/models/filters/CommonFilterSettings";

import { VueComponent } from "piramis-base-components/src/shared/types/VueComponent";

export type ModuleIcon = {
  type: "Custom"
  icon: VueComponent
} | {
  type: "Pack",
  name: string,
  color?: string
} & MaterialIcon

type MaterialIcon = {
  pack: 'material-icons'
  iconStyle?: 'outlined' | 'round' | 'sharp' | 'two-tone'
}

export interface IModuleConfig {
  enabled: boolean
  create_type: CreateTypeEnum
  conditions: Array<Array<IModuleConfigCondition>>
}

export interface IModuleConfigCondition {
  type: ModuleConditionTypeEnum
}

export enum ModuleConditionTypeEnum {
  Topics = 'Topics',
  Zone = 'Zone',
  Users = 'Users',
  WorkTime = 'WorkTime',
  ResolveFromRank = 'ResolveFromRank',
  ResolveFromXp = 'ResolveFromXp',
  ResolveFromRep = 'ResolveFromRep',
  ResolveFromAp = 'ResolveFromAp',
  Boosts = 'Boosts',
  UserType = 'UserType',
  MessageContentType = 'MessageContentType'
}

export interface IBaseModule {
  type: ModuleTypesEnum
  guid: string
  config: IModuleConfig
  params?: Record<string, any>
}

export interface IFilterModule extends IBaseModule {
  filterType: FilterTypes
}

export enum ModuleTypesEnum {
  ApiNotifyHandlerModule = 'ApiNotifyHandlerModule',
  AnchorsHandlerModule = 'AnchorsHandlerModule',
  ChatTopCommandHandlerModule = 'ChatTopCommandHandlerModule',
  ReferralModule = 'ReferralModule',

  ReportCommandHandlerModule = 'ReportCommandHandlerModule',
  CommandLimitHandlerModule = 'CommandLimitHandlerModule',
  ModerateCommandHandlerModule = 'ModerateCommandHandlerModule',
  NetworksModerateCommandHandlerModule = 'NetworksModerateCommandHandlerModule',
  RulesCommandHandlerModule = 'RulesCommandHandlerModule',

  //chat activity
  SendWakeupMessageModule = 'SendWakeupMessageModule',
  SendAdminMessageModule = 'SendAdminMessageModule',
  EntertainmentHandlerModule = 'EntertainmentHandlerModule',
  EntertainmentCallHandlerModule = 'EntertainmentCallHandlerModule',
  ChatCryptoCommandHandlerModule = 'ChatCryptoCommandHandlerModule',
  InactiveKickHandlerModule = 'InactiveKickHandlerModule',
  FirstInactiveKickHandlerModule = 'FirstInactiveKickHandlerModule',
  DailyReportModule = 'DailyReportModule',

  //moderation extra
  AlbumLimitHandlerModule = 'AlbumLimitHandlerModule',
  CloseChatHandlerModule = 'CloseChatHandlerModule',
  AutocorrectionHandlerModule = 'AutocorrectionHandlerModule',
  NewUsersAttackHandlerModule = 'NewUsersAttackHandlerModule',
  NsfwHandlerModule = 'NsfwHandlerModule',
  EditMediaHandlerModule = 'EditMediaHandlerModule',

  ReputationMessageHandlerModule = 'ReputationMessageHandlerModule',
  WarnCommandHandlerModule = 'WarnCommandHandlerModule',

  XpHandlerModule = 'XpHandlerModule',
  RanksHandlerModule = 'RanksHandlerModule',

  //facecontrol
  FacecontrolHandlerModule = 'FacecontrolHandlerModule',
  EnterBindedChannelHandlerModule = 'EnterBindedChannelHandlerModule',
  MessageBindedChannelHandlerModule = 'MessageBindedChannelHandlerModule',
  NewUsersLimitHandlerModule = 'NewUsersLimitHandlerModule',
  BotGuardHandlerModule = 'BotGuardHandlerModule',

  // filters
  StopWordFilterHandlerModule = 'StopWordFilterHandlerModule',
  StoryFilterHandlerModule = 'StoryFilterHandlerModule',
  VideoNoteFilterHandlerModule = 'VideoNoteFilterHandlerModule',
  SpoilerMessageFilterHandlerModule = 'SpoilerMessageFilterHandlerModule',
  ContactFilterHandlerModule = 'ContactFilterHandlerModule',
  DiceFilterHandlerModule = 'DiceFilterHandlerModule',
  AudioFilterHandlerModule = 'AudioFilterHandlerModule',
  HieroglyphMessageFilterHandlerModule = 'HieroglyphMessageFilterHandlerModule',
  RtlMessageFilterHandlerModule = 'RtlMessageFilterHandlerModule',
  VoiceFilterHandlerModule = 'VoiceFilterHandlerModule',
  AnimationFilterHandlerModule = 'AnimationFilterHandlerModule',
  VideoFilterHandlerModule = 'VideoFilterHandlerModule',
  PhotoFilterHandlerModule = 'PhotoFilterHandlerModule',
  DocumentFilterHandlerModule = 'DocumentFilterHandlerModule',
  RegularExpressionFilterHandlerModule = 'RegularExpressionFilterHandlerModule',
  PermittedWordFilterHandlerModule = 'PermittedWordFilterHandlerModule',
  CensureFilterHandlerModule = 'CensureFilterHandlerModule',
  CapsMessageFilterHandlerModule = 'CapsMessageFilterHandlerModule',
  MessageLengthFilterHandlerModule = 'MessageLengthFilterHandlerModule',
  ChannelMessageFilterHandlerModule = 'ChannelMessageFilterHandlerModule',
  AnyMessageFilterHandlerModule = 'AnyMessageFilterHandlerModule',
  ChatReplyFilterHandlerModule = 'ChatReplyFilterHandlerModule',
  ChannelReplyFilterHandlerModule = 'ChannelReplyFilterHandlerModule',
  BotReplyFilterHandlerModule = 'BotReplyFilterHandlerModule',
  StickerFilterHandlerModule = 'StickerFilterHandlerModule',
  FloodFilterHandlerModule = 'FloodFilterHandlerModule',

  BotCommandFilterHandlerModule = 'BotCommandFilterHandlerModule',
  StickerPackFilterHandlerModule = 'StickerPackFilterHandlerModule',
  EmailLinksFilterHandlerModule = 'EmailLinksFilterHandlerModule',
  EmojiFilterHandlerModule = 'EmojiFilterHandlerModule',
  ReferralFilterHandlerModule = 'ReferralFilterHandlerModule',

  WebLinksFilterHandlerModule = 'WebLinksFilterHandlerModule',
  SelfLinksFilterHandlerModule = 'SelfLinksFilterHandlerModule',

  LocationFilterHandlerModule = 'LocationFilterHandlerModule',

  SummarizeMessageHandlerModule = 'SummarizeMessageHandlerModule',
  SummarizePostHandlerModule = 'SummarizePostHandlerModule',
//   greeting
  LeaveUsersHandlerModule = 'LeaveUsersHandlerModule',
  SilentAdminInviteHandlerModule = 'SilentAdminInviteHandlerModule',
  SilentInviteHandlerModule = 'SilentInviteHandlerModule',
  NewUserDeprecatedTriggersHandlerModule = 'NewUserDeprecatedTriggersHandlerModule',
  NewUserTriggersHandlerModule = 'NewUserTriggersHandlerModule',
  NewUsersHandlerModule = 'NewUsersHandlerModule',

  FirstCommentHandlerModule = 'FirstCommentHandlerModule',
  RestrictCommentHandlerModule = 'RestrictCommentHandlerModule',

  ChatPriorityCommandHandlerModule = 'ChatPriorityCommandHandlerModule',
  ChatAnonAdminRequestHandlerModule = 'ChatAnonAdminRequestHandlerModule',
  GroupStateHandlerModule = 'GroupStateHandlerModule',
  LogChannelHandlerModule = 'LogChannelHandlerModule',
  GroupButtonReactionRequestHandlerModule = 'GroupButtonReactionRequestHandlerModule',
  CloseFilterChainHandlerModule = 'CloseFilterChainHandlerModule',
  TriggerGroupMessageHandlerModule = 'TriggerGroupMessageHandlerModule',
  TriggerFormHandlerModule = 'TriggerFormHandlerModule',
  VotebanHandlerModule = 'VotebanHandlerModule',

  ToxicFilterHandlerModule = 'ToxicFilterHandlerModule',
  LanguageFilterHandlerModule = 'LanguageFilterHandlerModule',
  SpamReactionHandlerModule = 'SpamReactionHandlerModule',
}

export enum CreateTypeEnum {
  Private = 'Private',
  Auto = 'Auto',
  Manual = 'Manual',
  Network = 'Network',
}

export enum ZoneTypeEnum {
  All = 'All',
  Comments = 'Comments',
  GroupMessages = 'GroupMessages',
  CommentsNotMembers = 'CommentsNotMembers',
  CommentsMembers = 'CommentsMembers',
}

export enum ModuleGroupEnum {
  UsersModeration = 'users-moderation',
  MessagesModeration = 'messages-moderation',
  ModerationCommands = 'commands',
  ActivityBoost = 'activity',
  Extra = 'extra',
}

export type ModuleData = { modules: Array<IBaseModule> }

export type NewUserFilterFields = Pick<CommonFilterSettings, 'first_message_punish_action' | 'new_limit_timeout' | 'new_limit_msg_count' | 'new_limit_punish_action'>
export type EditedMessageFilterFields = Pick<CommonFilterSettings, 'process_edit_separately' | 'remove_edited_timeout' | 'remove_edited_msg' | 'remove_edited_punish_action'>
