import BaseModule from "@/includes/logic/Modules/models/BaseModule";
import { ModuleTypesEnum } from "@/includes/logic/Modules/types/types";
import ReputationCalculationMode
  from "@/includes/logic/Modules/models/modules/ReputationMessageHandlerModule/ReputationCalculationMode/ReputationCalculationMode";
import { repCalcModeSubTypes } from "@/includes/logic/Modules/models/modules/ReputationMessageHandlerModule/ReputationCalculationMode/repCalcModeSubTypes";
import { ReactionEmojiEnum, ReputationModeEnum } from "@/includes/logic/Modules/models/modules/ReputationMessageHandlerModule/types";
import ReputationTrigger
  from "@/includes/logic/Modules/models/modules/ReputationMessageHandlerModule/ReputationTrigger";

import { BlackWhiteListItem } from "piramis-base-components/src/components/BlackWhiteList/types";
import MessageEditorWithMediaData
  from "piramis-base-components/src/components/NewMessageEditors/NewMessageEditorWithMedia/types";
import { RawChipItem } from "piramis-base-components/src/components/TypedChips/typedChips.types";

import { Type } from "class-transformer";

export default class ReputationMessageHandlerModule extends BaseModule {
  type: ModuleTypesEnum = ModuleTypesEnum.ReputationMessageHandlerModule;

  @Type(() => ReputationMessageHandlerModuleParams)
  params!: ReputationMessageHandlerModuleParams;
}

export class ReputationValueType {
  enabled!: boolean

  triggers!: BlackWhiteListItem<RawChipItem>

  @Type(() => MessageEditorWithMediaData)
  message!: Array<MessageEditorWithMediaData>

  @Type(() => MessageEditorWithMediaData)
  admin_message!: Array<MessageEditorWithMediaData>

  actor_reaction_emoji!: ReactionEmojiEnum | null
  target_reaction_emoji!: ReactionEmojiEnum | null

  reaction_count!: number

  special_reactions!: Array<ReactionEmojiEnum>
}

class ReputationMessageHandlerModuleParams {
  @Type(() => ReputationValueType)
  positive !: ReputationValueType

  @Type(() => ReputationValueType)
  negative !: ReputationValueType

  reputation_with_mention!: boolean

  @Type(() => ReputationTrigger)
  reputation_special_triggers!: Array<ReputationTrigger>

  reputation_mode!: ReputationModeEnum

  @Type(() => ReputationCalculationMode, {
    keepDiscriminatorProperty: true,
    discriminator: {
      property: 'type',
      subTypes: repCalcModeSubTypes
    },
  })
  reputation_calculation_mode!: ReputationCalculationMode

  @Type(() => MessageEditorWithMediaData)
  reputation_overtake_message!: Array<MessageEditorWithMediaData>

  chat_reputation_min_interval!: number
  user_reputation_min_interval!: number

  allow_reputation_only_from_whitelist!: boolean
  reputation_users_whitelist!: Record<number, string>
}

