import { ModuleTypesEnum } from "@/includes/logic/Modules/types/types";
import FilterModule from "@/includes/logic/Modules/models/filters/FilterModule";

import { BlackWhiteListItem } from "piramis-base-components/src/components/BlackWhiteList/types";
import { RawChipItem } from "piramis-base-components/src/components/TypedChips/typedChips.types";

import { Type } from "class-transformer";

export default class BotCommandFilterHandlerModule extends FilterModule {
  type: ModuleTypesEnum = ModuleTypesEnum.BotCommandFilterHandlerModule;

  @Type(() => BotCommandFilterHandlerModuleParams)
  params!: BotCommandFilterHandlerModuleParams
}

class BotCommandFilterHandlerModuleParams {
  bot_commands_whitelist!: BlackWhiteListItem<RawChipItem>
  bot_commands_strict_mode!: boolean
}
