import BaseModule from "@/includes/logic/Modules/models/BaseModule";
import { ModuleTypesEnum } from "@/includes/logic/Modules/types/types";

import { Type } from "class-transformer";
import MessageEditorWithMediaData
  from "piramis-base-components/src/components/NewMessageEditors/NewMessageEditorWithMedia/types";

export default class SummarizeMessageHandlerModule extends BaseModule {
  type: ModuleTypesEnum = ModuleTypesEnum.SummarizeMessageHandlerModule;

  @Type(() => SummarizeMessageHandlerModuleParams)
  params!: SummarizeMessageHandlerModuleParams;
}

class SummarizeMessageHandlerModuleParams {
  enable_daily_digest!: boolean
  daily_digest_time!: string
  @Type(() => MessageEditorWithMediaData)
  daily_digest_message!: Array<MessageEditorWithMediaData>
}
