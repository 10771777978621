import { ModuleGroupEnum, ModuleIcon } from "@/includes/logic/Modules/types/types";
import ModuleBuilder from "@/includes/logic/Modules/Builders/ModuleBuilder";

import SpamReactionHandlerModule from "./SpamReactionHandlerModule";
import store from "@/store/store";
import { ADVANCED_TAG, EXPERIMENTAL_TAG } from "@/includes/constants";
import { TagData } from "piramis-base-components/src/components/Tags/types";

export default class SpamReactionHandlerModuleBuilder extends ModuleBuilder {
  model!: SpamReactionHandlerModule;

  view: ModuleBuilder['view'] = () => import('../../../../../../components/Modules/modules/SpamReactionHandlerModuleView.vue')
  group: ModuleGroupEnum = ModuleGroupEnum.Extra

  icon: ModuleIcon = {
    type: "Pack",
    pack: 'material-icons',
    name: 'emoji_symbols',
  }

  tags(): Array<TagData> | null {
    return EXPERIMENTAL_TAG
  }

  tariffTags(): Array<TagData> | null {
    return store.getters.isChatSet && (!store.getters.isChatLicenseExists || !store.getters.isChatAtLeastAdvanced) ? ADVANCED_TAG : null
  }
}

