import { ModuleTypesEnum } from "@/includes/logic/Modules/types/types";
import FilterModule from "@/includes/logic/Modules/models/filters/FilterModule";

import { BlackWhiteListItem } from "piramis-base-components/src/components/BlackWhiteList/types";

import { Type } from "class-transformer";

export default class BotReplyFilterHandlerModule extends FilterModule {
  type: ModuleTypesEnum = ModuleTypesEnum.BotReplyFilterHandlerModule;

  @Type(() => BotReplyFilterHandlerModuleParams)
  params!: BotReplyFilterHandlerModuleParams
}

class BotReplyFilterHandlerModuleParams {
    bot_reply_by_user!: boolean
    bot_reply_by_via!: boolean
    bots_for_reply_whitelist!: BlackWhiteListItem<string>
}
