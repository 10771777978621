export type FormsState = {
  questionnaire: {
    forms: Array<any> | null
  }
}

const formsState: FormsState = {
  questionnaire: {
    forms: null
  }
}

export default formsState

