import { ModuleGroupEnum, ModuleIcon } from "@/includes/logic/Modules/types/types";
import ModuleBuilder from "@/includes/logic/Modules/Builders/ModuleBuilder";

import MessageLengthFilterHandlerModule from "./MessageLengthFilterHandlerModule";

export default class MessageLengthFilterHandlerModuleBuilder extends ModuleBuilder {
  model!: MessageLengthFilterHandlerModule;

  group: ModuleGroupEnum = ModuleGroupEnum.MessagesModeration
  icon: ModuleIcon = {
    type: "Pack",
    pack: 'material-icons',
    name: "linear_scale",
    color: "rgba(var(--a-primary), 1)"
  };
  view =  () => import('@/components/Modules/modules/Filters/MessageLengthFilterHandlerModuleView.vue')

  aliases = [ 'фильтры', 'удалять', 'удалить', ]

}
