import BaseModule from "@/includes/logic/Modules/models/BaseModule";
import { ModuleTypesEnum } from "@/includes/logic/Modules/types/types";

import { Type } from "class-transformer";
import MessageEditorWithMediaData
  from "piramis-base-components/src/components/NewMessageEditors/NewMessageEditorWithMedia/types";
import { WeekDaysEnum } from "@/includes/logic/Modules/models/modules/CloseChatHandlerModule/types";

export default class SummarizePostHandlerModule extends BaseModule {
  type: ModuleTypesEnum = ModuleTypesEnum.SummarizeMessageHandlerModule;

  @Type(() => SummarizePostHandlerModuleParams)
  params!: SummarizePostHandlerModuleParams;
}

class SummarizePostHandlerModuleParams {
  enable_daily_digest!: boolean
  daily_digest_time!: string
  @Type(() => MessageEditorWithMediaData)
  daily_digest_message!: Array<MessageEditorWithMediaData>

  enable_weekly_digest!: boolean
  weekly_digest_send_to_channel!: boolean
  weekly_time!: string
  day_of_week!:WeekDaysEnum
  @Type(() => MessageEditorWithMediaData)
  weekly_message!: Array<MessageEditorWithMediaData>

  enable_monthly_digest!: boolean
  monthly_digest_send_to_channel!: boolean
  monthly_time!: string
  day_of_month!: number
  @Type(() => MessageEditorWithMediaData)
  monthly_message!: Array<MessageEditorWithMediaData>
}
