export default {
  name  : "chatkeeperbot",
  siteUrl: "https://chatkeeper.app/",
  instructionUrl: "https://docs.chatkeeper.info/",
  assets: {
    images: {
      logo: {
        light: require("@/assets/images/logo/logo.png"),
      },
      fullLogo: {
        light: require("@/assets/images/logo/text-logo-dark.png"),
      }
    },
  },
  options: [],
  notAvailableRoutes: [
    // "userInfo"
  ],
  theme : {
    light: {
      primary: "#39f",
      success: "#28C76F",
      danger : "#EA5455",
      warning: "#FF9F43",
      dark   : "#1E1E1E",
    },
  },
  tariffs: {
    starter: {
      img: require("@/assets/images/payments/tariffs/chatkeeper/starter.png"),
      color: 'rgb(181,192,212)'
    },
    advanced: {
      img: require("@/assets/images/payments/tariffs/chatkeeper/advanced.png"),
      color: 'rgb(241,154,64)'
    },
    ultimate: {
      img: require("@/assets/images/payments/tariffs/chatkeeper/ultimate.png"),
      color: 'rgb(177,69,250)'
    },
  }
};
