import BaseModule from "@/includes/logic/Modules/models/BaseModule";
import { ModuleTypesEnum } from "@/includes/logic/Modules/types/types";
import { PunishActions } from "@/includes/logic/Modules/models/PunishActions/types";

import { Type } from "class-transformer";

export default class WarnCommandHandlerModule extends BaseModule {
  type: ModuleTypesEnum = ModuleTypesEnum.WarnCommandHandlerModule;

  @Type(() => WarnCommandHandlerModuleParams)
  params!: WarnCommandHandlerModuleParams;
}

class WarnCommandHandlerModuleParams {
  warning_punish_action!: PunishActions
  ban_punish_action!: PunishActions

  notice_before_ban_count!: number

  take_away_reputation!: number
  take_away_xp!: number

  first_message_punish_action!: PunishActions

  allowed_interval!: number
  allowed_interval_round!: number

  journal_moderation_warn_warn!: boolean
  journal_moderation_ban_warn!: boolean
}
