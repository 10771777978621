import BaseModule from "@/includes/logic/Modules/models/BaseModule";
import { ModuleTypesEnum } from "@/includes/logic/Modules/types/types";
import AnchorData from "@/includes/logic/Anchors/AnchorData";

import { Type } from "class-transformer";
import MessageEditorWithMediaData
  from "piramis-base-components/src/components/NewMessageEditors/NewMessageEditorWithMedia/types";

export default class ChatTopCommandHandlerModule extends BaseModule {
  type: ModuleTypesEnum = ModuleTypesEnum.ChatTopCommandHandlerModule;

  @Type(() => ChatTopCommandHandlerModuleParams)
  params!: ChatTopCommandHandlerModuleParams;
}

class ChatTopCommandHandlerModuleParams {
      @Type(() => MessageEditorWithMediaData)
      toprankmessage!: Array<MessageEditorWithMediaData>

      @Type(() => MessageEditorWithMediaData)
      bottomrankmessage!: Array<MessageEditorWithMediaData>

      @Type(() => MessageEditorWithMediaData)
      toprefmessage!: Array<MessageEditorWithMediaData>

      @Type(() => MessageEditorWithMediaData)
      bottomrefmessage!: Array<MessageEditorWithMediaData>

      @Type(() => MessageEditorWithMediaData)
      toprepmessage!: Array<MessageEditorWithMediaData>

      @Type(() => MessageEditorWithMediaData)
      bottomrepmessage!: Array<MessageEditorWithMediaData>

      @Type(() => MessageEditorWithMediaData)
      topapmessage!: Array<MessageEditorWithMediaData>

      @Type(() => MessageEditorWithMediaData)
      bottomapmessage!: Array<MessageEditorWithMediaData>

      @Type(() => MessageEditorWithMediaData)
      topmsg!: Array<MessageEditorWithMediaData>

      @Type(() => MessageEditorWithMediaData)
      bottommsg!: Array<MessageEditorWithMediaData>

      @Type(() => MessageEditorWithMediaData)
      topxp!: Array<MessageEditorWithMediaData>

      @Type(() => MessageEditorWithMediaData)
      bottomxp!: Array<MessageEditorWithMediaData>
}
