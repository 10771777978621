import { ModuleTypesEnum } from "@/includes/logic/Modules/types/types";
import FilterModule from "@/includes/logic/Modules/models/filters/FilterModule";

import { BlackWhiteListItem } from "piramis-base-components/src/components/BlackWhiteList/types";

import { Type } from "class-transformer";

export default class ChatReplyFilterHandlerModule extends FilterModule {
  type: ModuleTypesEnum = ModuleTypesEnum.ChatReplyFilterHandlerModule;

  @Type(() => ChatReplyFilterHandlerModuleParams)
  params!: ChatReplyFilterHandlerModuleParams
}

class ChatReplyFilterHandlerModuleParams {
  chats_whitelist!: BlackWhiteListItem<string>

  allow_self_forward!: boolean
}
