import { ModuleGroupEnum, ModuleIcon } from "@/includes/logic/Modules/types/types";
import ModuleBuilder from "@/includes/logic/Modules/Builders/ModuleBuilder";

import StopWordFilterHandlerModule from "./StopWordFilterHandlerModule";

export default class StopWordFilterHandlerModuleBuilder extends ModuleBuilder {
  model!: StopWordFilterHandlerModule;

  group: ModuleGroupEnum = ModuleGroupEnum.MessagesModeration
  icon: ModuleIcon = {
    type: "Pack",
    pack: 'material-icons',
    name: "remove_circle",
    color: "rgba(var(--a-danger), 1)"
  };
  view =  () => import('@/components/Modules/modules/Filters/StopWordFilterHandlerModuleView.vue')

  aliases = [ 'стоп-слово', 'стоп слово', 'фильтры', 'удалять', 'удалить','фильтры', 'удалять', 'удалить' ]

}
