import mainConfigs from "./chat/mainConfigs";
import achievements from "./achievements/mutations";
import chatTriggersMutations from "./triggers/mutations";
import deepObjectKeyPut from "@/assets/utils/deepObjectKeyPut";
import { RootState } from "@/store/state";
import { BaseChatInfo, ChatConfig } from "@/includes/types/Chat/types";

import { merge } from "lodash";

export default {
  // Основныйе настройки
  ...mainConfigs,
  ...achievements,
  // haveChats
  updateChatInfo(state: RootState, payload: {chatId: BaseChatInfo['chat_id'], config: Partial<BaseChatInfo>}) {
    const chat = state.chatState.chatsInfo?.chats.find(c => c.chat_id === payload.chatId)

    if (chat) {
      merge(chat, payload.config)
    }

  },
  SET_BRAND_BOT_CONFIG(state:RootState, n: ChatConfig['brand']) {
    state.chatState.brandConfig = n; // object/null
  },
  ...chatTriggersMutations,
  setObjectField(state:RootState, { object, objectPath, value }: any) {
    deepObjectKeyPut(object, objectPath, value);
  },
  EXEC_CALLBACK_IN_STORE(state:RootState, { type = 'unknown', callback }: { type: any, callback: () => void }) {
    callback()
  },
  clearChatStateFields(state: RootState) {
    state.chatState.chat = null
    state.chatState.brandConfig = null
    state.chatState.backupConfig = null
  }
};
