import BaseModule from "@/includes/logic/Modules/models/BaseModule";
import { ModuleTypesEnum } from "@/includes/logic/Modules/types/types";
import { PunishActions } from "@/includes/logic/Modules/models/PunishActions/types";

import { Type } from "class-transformer";

export default class NsfwHandlerModule extends BaseModule {
  type: ModuleTypesEnum = ModuleTypesEnum.NsfwHandlerModule;

  @Type(() => NsfwHandlerModuleParams)
  params!: NsfwHandlerModuleParams;
}

class NsfwHandlerModuleParams {
  punish_action!: PunishActions
}

