import { ModuleTypesEnum } from "@/includes/logic/Modules/types/types";
import FilterModule from "@/includes/logic/Modules/models/filters/FilterModule";

import { BlackWhiteListItem } from "piramis-base-components/src/components/BlackWhiteList/types";

import { Type } from "class-transformer";

export default class SelfLinksFilterHandlerModule extends FilterModule {
  type: ModuleTypesEnum = ModuleTypesEnum.WebLinksFilterHandlerModule;

  @Type(() => SelfLinksFilterHandlerModuleParams)
  params!: SelfLinksFilterHandlerModuleParams
}

class SelfLinksFilterHandlerModuleParams {
  tg_links_whitelist!: BlackWhiteListItem<string>
  search_links_in_quotes!: boolean
  self_links_strict_mode!: boolean
  self_links_dont_use_join!: boolean
  search_links_in_qr_codes!: boolean
  self_links_accept_mention_users!: boolean

  self_links_whitelist_allow_users!: true
  self_links_whitelist_allow_bots!: false
  self_links_whitelist_allow_chats!: false
  self_links_whitelist_allow_channels!: false
  self_links_blacklist_forbid_users!: true
  self_links_blacklist_forbid_bots!: false
  self_links_blacklist_forbid_chats!: false
  self_links_blacklist_forbid_channels!: false
}
