import { ModuleGroupEnum, ModuleIcon } from "@/includes/logic/Modules/types/types";
import ModuleBuilder from "@/includes/logic/Modules/Builders/ModuleBuilder";

import SilentAdminInviteHandlerModule from "./SilentAdminInviteHandlerModule";

export default class SilentAdminInviteHandlerModuleBuilder extends ModuleBuilder {
  model!: SilentAdminInviteHandlerModule;

  group: ModuleGroupEnum = ModuleGroupEnum.UsersModeration
  view = null

  icon: ModuleIcon = {
    type: "Pack",
    pack: 'material-icons',
    name: 'do_not_touch',
  }
}

