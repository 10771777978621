import { GreetingProtectionTypeEnum } from "../types";
import GreetingProtection from "./GreetingProtection";
import BindedChannelGreetingProtection from "./BindedChannelGreetingProtection";
import FormValidationGreetingProtection from "./FormValidationGreetingProtection";
import InviteValidationGreetingProtection from "./InviteValidationGreetingProtection";
import MathCaptchaGreetingProtection from "./MathCaptchaGreetingProtection";
import NoneGreetingProtection from "./NoneGreetingProtection";
import StandardGreetingProtection from "./StandardGreetingProtection";
import VariableCaptchaGreetingProtection from "./VariableCaptchaGreetingProtection";
import ExternalValidationGreetingProtection from "./ExternalValidationGreetingProtection";

import { ClassConstructor } from "class-transformer";

export const greetingProtectionSubTypes: Array<{ name: GreetingProtectionTypeEnum, value: ClassConstructor<GreetingProtection> }> = [
  {
    name: GreetingProtectionTypeEnum.BindedChannel,
    value: BindedChannelGreetingProtection,
  },
  {
    name: GreetingProtectionTypeEnum.FormValidation,
    value: FormValidationGreetingProtection,
  },
  {
    name: GreetingProtectionTypeEnum.InviteValidation,
    value: InviteValidationGreetingProtection,
  },
  {
    name: GreetingProtectionTypeEnum.MathCaptcha,
    value: MathCaptchaGreetingProtection,
  },
  {
    name: GreetingProtectionTypeEnum.None,
    value: NoneGreetingProtection,
  },
  {
    name: GreetingProtectionTypeEnum.Standart,
    value: StandardGreetingProtection,
  },
  {
    name: GreetingProtectionTypeEnum.VariableCaptcha,
    value: VariableCaptchaGreetingProtection,
  },
  {
    name: GreetingProtectionTypeEnum.ExternalValidation,
    value: ExternalValidationGreetingProtection
  }
]
