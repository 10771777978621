import { ModuleGroupEnum, ModuleIcon } from "@/includes/logic/Modules/types/types";
import ModuleBuilder from "@/includes/logic/Modules/Builders/ModuleBuilder";

import SummarizePostHandlerModule from "./SummarizePostHandlerModule";
import store from "@/store/store";
import { AI_EXPERIMENTAL_TAG } from "@/includes/constants";

import { TagData } from "piramis-base-components/src/components/Tags/types";

export default class SummarizePostHandlerModuleBuilder extends ModuleBuilder {
  model!: SummarizePostHandlerModule;

  view: ModuleBuilder['view'] = () => import('../../../../../../components/Modules/modules/SummarizePostHandlerModuleView.vue')
  group: ModuleGroupEnum = ModuleGroupEnum.Extra

  icon: ModuleIcon = {
    type: "Pack",
    pack: 'material-icons',
    name: 'filter',
  }

  tags(): Array<TagData> | null {
    return AI_EXPERIMENTAL_TAG
  }

  isVisible(): boolean {
    return store.getters.isChatSet && store.state.chatState.chat!.version >= 500
  }
}

