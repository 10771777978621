


















import { Vue, Component, Prop, Emit } from 'vue-property-decorator';

@Component({})
export default class PiWizardHeader extends Vue {
  @Prop() title!: string

  @Prop() hasCloseButton!: boolean

  @Emit()
  closeClick() {
    return null
  }
}
