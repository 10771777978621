import { ModuleTypesEnum } from "@/includes/logic/Modules/types/types";
import FilterModule from "@/includes/logic/Modules/models/filters/FilterModule";
import CommonFilterSettings from "@/includes/logic/Modules/models/filters/CommonFilterSettings";

import { Type } from "class-transformer";

export class FloodCommonSettings extends CommonFilterSettings {
  can_mute_media_when_possibly!: boolean
}

export default class FloodFilterHandlerModule extends FilterModule {
  @Type(() => FloodCommonSettings)
  filter!: FloodCommonSettings

  type: ModuleTypesEnum = ModuleTypesEnum.FloodFilterHandlerModule;

  @Type(() => FloodFilterHandlerModuleParams)
  params!: FloodFilterHandlerModuleParams
}

class FloodFilterHandlerModuleParams {
  delete_recent_messages!: boolean
  flood_message_count!: number
  flood_message_interval!: number
  flood_min_message_interval!: number

  chat_options!: Array<FloodOptionsEnum>
  user_options!: Array<FloodOptionsEnum>
}

export enum FloodOptionsEnum {
  Text = 'Text',
  Audio = 'Audio',
  Document = 'Document',
  Sticker = 'Sticker',
  Photo = 'Photo',
  Video = 'Video',
  Animation = 'Animation',
  Contact = 'Contact',
  Voice = 'Voice',
  VideoNote = 'VideoNote',
  Poll = 'Poll',
  Dice = 'Dice'
}
