import BaseModule from "@/includes/logic/Modules/models/BaseModule";
import { ModulesTypes } from "@/includes/logic/Modules/ModulesTypes";

import { Type } from "class-transformer";
import WeightModule from "@/includes/logic/Modules/models/WeightModule";

export class Modules {

  @Type(() => BaseModule, {
    keepDiscriminatorProperty: true,
    discriminator: {
      property: 'type',
      subTypes: ModulesTypes
    },
  })
  modules!: Array<BaseModule>

}

export class WeightModules {
  @Type(() => BaseModule, {
    keepDiscriminatorProperty: true,
    discriminator: {
      property: 'type',
      subTypes: ModulesTypes
    },
  })
  modules!: Array<WeightModule>

}

