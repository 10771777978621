import { getPostBoards } from "@/includes/Controllers/Boards.controller";

export default {
	requestPostBoards({ commit }) {
		return getPostBoards()
		     .then((boards) => {
			     commit("set_boards_to_store", boards);

			     return boards;
		     });
	},
	setCurrentBoard({ commit, state }, boardId) {
		if (state.postsPlanner.boards !== null) {
			const board = state.postsPlanner.boards.find(b => b.board.token_id === +boardId)

			if (board) {
				commit('set_current_board', board)

				return board
			}

			return null
		}

	}
};
