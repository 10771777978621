import { AchievementItem } from "@/includes/types/Achievements";
import { RootState } from "@/store/state";

import { Commit } from "vuex";

export default {
  addAchievement({ commit }: { state: RootState, commit: Commit }, item: AchievementItem) {
    commit('ADD_ACHIEVEMENT', item)
  },
  updateAchievement({ commit }: { state: RootState, commit: Commit }, item: AchievementItem) {
    commit('UPDATE_ACHIEVEMENT', item)
  },
  removeAchievement({ commit }: { state: RootState, commit: Commit }, id: AchievementItem['id']) {
    commit('REMOVE_ACHIEVEMENT', id)
  }
}

