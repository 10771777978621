import { ModuleGroupEnum, ModuleIcon } from "@/includes/logic/Modules/types/types";
import ModuleBuilder from "@/includes/logic/Modules/Builders/ModuleBuilder";
import NsfwHandlerModule from "./NsfwHandlerModule";

import { TagData } from "piramis-base-components/src/components/Tags/types";

export default class NsfwHandlerModuleBuilder extends ModuleBuilder {
  model!: NsfwHandlerModule;

  view: ModuleBuilder['view'] = () => import('../../../../../../components/Modules/modules/ModerationExtra/NsfwHandlerModuleView.vue')
  group: ModuleGroupEnum = ModuleGroupEnum.MessagesModeration

  icon: ModuleIcon = {
    type: "Pack",
    name: 'favorite',
    pack: "material-icons",
  }

  tariffTags(): Array<TagData> | null {
    return this.checkLimitOption('delete_porno')
  }
}

