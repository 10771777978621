import { ModuleTypesEnum } from "@/includes/logic/Modules/types/types";
import FilterModule from "@/includes/logic/Modules/models/filters/FilterModule";
import { Type } from "class-transformer";

export default class VoiceFilterHandlerModule extends FilterModule {
  type: ModuleTypesEnum = ModuleTypesEnum.VoiceFilterHandlerModule;

  @Type(() => VoiceFilterHandlerModuleParams)
  params!: VoiceFilterHandlerModuleParams;
}

class VoiceFilterHandlerModuleParams {
    voice_min_file_size!: number
    voice_max_file_size!: number
    voice_min_duaration!: number
    voice_max_duaration!: number
}
