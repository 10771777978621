import { cloneDeep, remove } from "lodash";

export default {
	updateTriggers(state, val) {
		state.triggersState.triggers = val;
	},
	updateFrontendTriggers(state, val) {
		state.triggersState.frontendTriggers = val;
	},
	deleteFrontendTrigger(state, id) {
		let triggersCopy = state.triggersState.frontendTriggers.slice();
		remove(triggersCopy, function(el) {
			return el.id === id;
		});
		state.triggersState.frontendTriggers = triggersCopy;
	},
	setActiveTrigger(state, trigger) {
		state.triggersState.activeTrigger = cloneDeep(trigger);
	},
	setNewTrigger(state, trigger) {
		state.triggersState.newTrigger = cloneDeep(trigger);
	},
	resetNewTrigger(state) {
	  state.triggersState.newTrigger = null;
	},
	resetFrontendTriggers(state) {
	  state.triggersState.frontendTriggers = null
	  state.triggersState.triggers = {}
	},
  	updatedTriggerId(state, triggerId) {
  	  state.triggersState.updatedTriggerId = triggerId;
  	},
};
