































import PiWizardHeader from "@/components/Wizard/components/PiWizardHeader.vue";
// import { chatWizardConfig } from "@/components/Wizard/WizardNew/ChatWizardConfig";
import { prepareSteps } from "@/components/Wizard/logic";
import {
  ControlButton, PiWizardConfig,
  PiWizardNavigationButtonEnum,
  WizardNavigationButton
} from "@/components/Wizard/types";

import Overlay from 'piramis-base-components/src/components/Overlay/Overlay.vue';

import { Component, Prop, Ref, VModel, Watch } from 'vue-property-decorator';
import { cloneDeep } from "lodash";
import Vue from "vue";

@Component({
  components: {
    PiWizardHeader,
    Overlay
  }
})
export default class NewWizard extends Vue {
  @VModel({ type: Boolean }) isWizardOpen!: boolean

  @Prop() chatWizardConfig!: PiWizardConfig

  currentStep: string | null = null

  stepsModel: any | null = null

  @Ref('overlayRef') overlayView!: Overlay

  @Watch('isWizardOpen')
  onWizardToggle(state: boolean) {
    if (!state) {
      this.currentStep = null
      this.stepsModel = null
    } else {
      this.currentStep = Object.keys(this.chatWizardConfig.pages)[0]
      this.stepsModel = prepareSteps(this.chatWizardConfig)
    }
  }

  mounted() {
    this.currentStep = Object.keys(this.chatWizardConfig.pages)[0]
    this.stepsModel = prepareSteps(this.chatWizardConfig)
  }

  changeStep(key: string) {
    this.currentStep = key

    this.overlayView.$el.scroll({ top: 0 })
  }

  setStepModel(stepKey: string, model: any) {
    this.stepsModel[stepKey] = cloneDeep(model)
  }

  get closeWizardCallback() {
    return () => this.isWizardOpen = false
  }

  navigationButtonClick(payload: { button: WizardNavigationButton, stepModel: any }) {
    if (payload.button.type === PiWizardNavigationButtonEnum.BackButton || payload.button.type === PiWizardNavigationButtonEnum.NextButton) {
      if (payload.button.type === PiWizardNavigationButtonEnum.NextButton) {
        //set model for current step
        this.setStepModel(this.currentStep!, payload.stepModel)

        //set model for the next step
        this.setStepModel(payload.button.gotoPageKey, payload.stepModel)
      }

      // change step
      this.changeStep(payload.button.gotoPageKey)
    } else if (payload.button.type === PiWizardNavigationButtonEnum.CompleteButton) {
      payload.button.onComplete(payload.stepModel, this.closeWizardCallback)
    }
  }

  controlButtonClick(payload: { button: ControlButton, stepModel: any }) {
    if (payload.button.gotoPageKey) {
      this.setStepModel(payload.button.gotoPageKey, payload.stepModel)
      this.changeStep(payload.button.gotoPageKey)
    }

    if (typeof payload.button.onClick === 'function') {
      payload.button.onClick(payload.stepModel, this.closeWizardCallback)
    }
  }
}
