import { ModuleTypesEnum } from "@/includes/logic/Modules/types/types";
import FilterModule from "@/includes/logic/Modules/models/filters/FilterModule";

import { Type } from "class-transformer";

export default class RtlMessageFilterHandlerModule extends FilterModule {
  type: ModuleTypesEnum = ModuleTypesEnum.RtlMessageFilterHandlerModule;

  @Type(() => RtlMessageFilterHandlerModuleParams)
  params!: RtlMessageFilterHandlerModuleParams;
}

class RtlMessageFilterHandlerModuleParams {
  rtl_message_max_percent!: number
}
