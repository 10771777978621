import BaseModule from "@/includes/logic/Modules/models/BaseModule";
import { ModuleTypesEnum } from "@/includes/logic/Modules/types/types";

import MessageEditorWithMediaData
  from "piramis-base-components/src/components/NewMessageEditors/NewMessageEditorWithMedia/types";

import { Type } from "class-transformer";

export default class SendWakeupMessageModule extends BaseModule {
  type: ModuleTypesEnum = ModuleTypesEnum.SendWakeupMessageModule;

  @Type(() => SendWakeupMessageModuleParams)
  params!: SendWakeupMessageModuleParams;
}

class SendWakeupMessageModuleParams {
  @Type(() => MessageEditorWithMediaData)
    wakeup_message!: Array<MessageEditorWithMediaData>

    send_wakeup_message!: boolean
    send_smile_message!: boolean
    wakeup_message_interval!: number
}
