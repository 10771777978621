import { ReputationCalculationModeEnum } from "../types";
import ComplexReputationCalculationMode from "./ComplexReputationCalculationMode";
import RandomReputationCalculationMode from "./RandomReputationCalculationMode";
import ReputationCalculationMode from "./ReputationCalculationMode";
import StandartReputationCalculationMode from "./StandartReputationCalculationMode";

import { ClassConstructor } from "class-transformer";

export const repCalcModeSubTypes: Array<{ name: ReputationCalculationModeEnum, value: ClassConstructor<ReputationCalculationMode> }> = [
  {
    name: ReputationCalculationModeEnum.Standard,
    value: StandartReputationCalculationMode
  },
  {
    name: ReputationCalculationModeEnum.Random,
    value: RandomReputationCalculationMode
  },
  {
    name: ReputationCalculationModeEnum.Complex,
    value: ComplexReputationCalculationMode
  }
]
