import BaseModule from "@/includes/logic/Modules/models/BaseModule";
import { ModuleGroupEnum, ModuleIcon } from '@/includes/logic/Modules/types/types'
import { getTariffTranslationKey } from "@/includes/helpers/tariffHelper";
import i18n from "@/i18n/i18n";
import store from '@/store/store'
import FilterModule from "../models/filters/FilterModule";

import { VueComponent } from "piramis-base-components/src/shared/types/VueComponent";
import { TagData } from "piramis-base-components/src/components/Tags/types";

import { Exclude } from "class-transformer";
import { cloneDeep, isEmpty } from "lodash";
import Vue from "vue";

export default abstract class ModuleBuilder {
  abstract model: BaseModule

  @Exclude()
  modelBackup!: BaseModule

  @Exclude()
  abstract view: VueComponent | null

  @Exclude()
  abstract group: ModuleGroupEnum

  @Exclude()
  icon?: ModuleIcon

  @Exclude()
  aliases?: Array<string>

  hasEmptyParams() {
    return !(this.model instanceof FilterModule) && isEmpty(this.model.params)
  }

  setBuilderModel(moduleData: BaseModule) {
    this.model = cloneDeep(moduleData)
    this.modelBackup = cloneDeep(moduleData)
  }

  // async removeModule() {
  //   const module = cloneDeep(this.model);
  //
  //   module.config.enabled = false
  //
  //   let response
  //
  //   if (module.config.create_type === CreateTypeEnum.Manual || module.config.create_type === CreateTypeEnum.Auto) {
  //     response = await setModuleConfig(store.getters.chatId, module)
  //   }
  //   if (module.config.create_type === CreateTypeEnum.Network) {
  //     response = await NetworksApi.setNetworkModuleConfig()
  //   }
  //   // if (module.config.create_type === CreateTypeEnum.Network) {
  //   //
  //   // }
  //
  //   if (response) {
  //     return response
  //   }
  // }

  // async saveModule() {
  //   const module = cloneDeep(this.model);
  //
  //   const response = await setModuleConfig(store.getters.chatId, module)
  //
  //   if (response) {
  //     return response
  //   }
  // }

  protected checkLimitOption(key: string) {
    const { chat } = store.state.chatState

    if (chat && chat.limits) {
      if (!chat.limits.options.includes(key)) {
        return [ {
          'text': i18n.t(getTariffTranslationKey(Vue.prototype.$getOptionTariff(key))) as string,
          'color': store.state.themeConfig.getTariff(i18n.t(getTariffTranslationKey(Vue.prototype.$getOptionTariff(key)))).color
        } ]
      }

      return null
    }

    return null
  }

  hasLimits() {
    return false
  }

  tags(): Array<TagData> | null {
    return []
  }

  tariffTags(): Array<TagData> | null {
    return []
  }

  isSaveable() {
    return true
  }

  isRemovable() {
    return true
  }

  isVisible() {
    return true
  }
}
