import Vue              from "vue";
import Vuex             from "vuex";
import actions          from "./actions";
import getters          from "./getters";
import mutations        from "./mutations";
import rootState, { RootState } from "./state";

import metaModule       from "@/store/modules/metaModule";
import { store as pi } from 'piramis-base-components/src/components/Pi'

Vue.use(Vuex);

export default new Vuex.Store<RootState>({
	getters,
	mutations,
	state: rootState,
	actions,
	modules: {
		metaModule,
		pi
	},
	strict : false
});
