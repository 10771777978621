import { ModuleGroupEnum } from "@/includes/logic/Modules/types/types";
import ModuleBuilder from "@/includes/logic/Modules/Builders/ModuleBuilder";

import NewUserDeprecatedTriggersHandlerModule from "./NewUserDeprecatedTriggersHandlerModule";
import { TagData } from "piramis-base-components/src/components/Tags/types";
import i18n from "@/i18n/i18n";

export default class NewUserDeprecatedTriggersHandlerModuleBuilder extends ModuleBuilder {
  model!: NewUserDeprecatedTriggersHandlerModule;

  view: ModuleBuilder['view'] = () => import('../../../../../../../components/Modules/modules/Greeting/NewUserDeprecatedTriggersHandlerModuleView.vue')
  group: ModuleGroupEnum = ModuleGroupEnum.UsersModeration

  tags(): Array<TagData> | null {
    return [
      {
        text: i18n.t('deprecated_setting_tag_title').toString(),
        color: 'red'
      }
    ]
  }
}

