import { BaseNetworkData, NetworkConfig } from "@/includes/types/networks";
import i18n from "@/i18n/i18n";
import { BaseChatInfo } from "@/includes/types/Chat/types";

export function getNetworkTitle(network: BaseNetworkData) {
  if (network.name) {
    return network.name
  } else {
    return i18n.t(`networks_empty_name_title`, [ network.id ]).toString()
  }
}

export function getPrivacyAlias(name: BaseNetworkData['name']) {
  if (name) {
    return i18n.t(`networks_network_public`).toString()
  } else {
    return i18n.t(`networks_network_private`).toString()
  }
}

export function inactiveEntitiesChats(network: NetworkConfig, field: keyof Pick<NetworkConfig, 'group_without_modules' | 'group_without_triggers'>) {
  return network.chats_info.reduce((acc: Array<string>, chat: BaseChatInfo) => {
    if (network[field].includes(chat.chat_id)) {
      acc.push(chat.group_title)
    }

    return acc
  }, [])
      .join(', ')
}
